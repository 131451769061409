import styled from "styled-components";

export const BarChartStyled = styled.div`
  .ant-card {
    .ant-card-body {
      padding: 0px;
    }
  }
  .recharts-legend-wrapper {
    ul {
      display: flex;
      justify-content: flex-end;
      list-style-type: square;
      li {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 0 0 0 20px;
      }
    }
  }
`;
