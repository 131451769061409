export const subscriptionTabBlankSlate = (
  <svg width="100%" height="100%" viewBox="0 0 1466 820">
    <g id="subscription" transform="translate(-287 -284)">
      <path
        id="Path_2682"
        data-name="Path 2682"
        d="M0,0H466V736H0Z"
        transform="translate(287 368)"
        fill="#f0f0f0"
      />
      <path
        id="Path_2683"
        data-name="Path 2683"
        d="M0,0H466V736H0Z"
        transform="translate(787 368)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1354"
        data-name="Rectangle 1354"
        width="466"
        height="736"
        transform="translate(1287 368)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1388"
        data-name="Rectangle 1388"
        width="563"
        height="15"
        rx="7.5"
        transform="translate(287 284)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1515"
        data-name="Rectangle 1515"
        width="563"
        height="15"
        rx="7.5"
        transform="translate(287 317)"
        fill="#f0f0f0"
      />
    </g>
  </svg>
);

export const itemWithListBlackSlate = (
  <svg width="494" height="100%" viewBox="0 0 494 200.621">
    <g
      id="Item_With_List"
      data-name="Item With List"
      transform="translate(-287.5 -624)"
    >
      <line
        id="Line_383"
        data-name="Line 383"
        x2="494"
        transform="translate(287.5 663.747)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
        strokeDasharray="3 3"
        opacity="0.25"
      />
      <line
        id="Line_449"
        data-name="Line 449"
        x2="494"
        transform="translate(287.5 717.205)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
        strokeDasharray="3 3"
        opacity="0.25"
      />
      <line
        id="Line_450"
        data-name="Line 450"
        x2="494"
        transform="translate(287.5 770.663)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
        strokeDasharray="3 3"
        opacity="0.25"
      />
      <line
        id="Line_451"
        data-name="Line 451"
        x2="494"
        transform="translate(287.5 824.121)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
        strokeDasharray="3 3"
        opacity="0.25"
      />
      <rect
        id="Rectangle_1516"
        data-name="Rectangle 1516"
        width="176"
        height="15"
        rx="7.5"
        transform="translate(299 629)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1517"
        data-name="Rectangle 1517"
        width="176"
        height="15"
        rx="7.5"
        transform="translate(299 683)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1518"
        data-name="Rectangle 1518"
        width="176"
        height="15"
        rx="7.5"
        transform="translate(299 737)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1519"
        data-name="Rectangle 1519"
        width="176"
        height="15"
        rx="7.5"
        transform="translate(299 790)"
        fill="#f0f0f0"
      />
      <circle
        id="Ellipse_268"
        data-name="Ellipse 268"
        cx="12.5"
        cy="12.5"
        r="12.5"
        transform="translate(742.775 624)"
        fill="#f0f0f0"
      />
      <circle
        id="Ellipse_314"
        data-name="Ellipse 314"
        cx="12.5"
        cy="12.5"
        r="12.5"
        transform="translate(742.775 678)"
        fill="#f0f0f0"
      />
      <circle
        id="Ellipse_315"
        data-name="Ellipse 315"
        cx="12.5"
        cy="12.5"
        r="12.5"
        transform="translate(742.775 732)"
        fill="#f0f0f0"
      />
      <circle
        id="Ellipse_316"
        data-name="Ellipse 316"
        cx="12.5"
        cy="12.5"
        r="12.5"
        transform="translate(742.775 785)"
        fill="#f0f0f0"
      />
    </g>
  </svg>
);

export const sawsTableBlankSlate = (
  <svg width="100%" height="100%" viewBox="0 0 1584.124 121">
    <g id="SAWS" transform="translate(-287 -349)">
      <g id="Group_2166" data-name="Group 2166" transform="translate(-40.624)">
        <g id="Group_2158" data-name="Group 2158" transform="translate(0.376)">
          <text
            id="Role"
            transform="translate(351.624 368)"
            fontSize="18"
            fontFamily="OpenSansSemiBold, OpenSans"
            fontWeight="600"
          >
            <tspan x="0" y="0">
              Web Service
            </tspan>
          </text>
        </g>
      </g>
      <g id="Group_2167" data-name="Group 2167" transform="translate(-13)">
        <g id="Group_2159" data-name="Group 2159" transform="translate(0.376)">
          <text
            id="Description"
            transform="translate(541.624 368)"
            fontSize="18"
            fontFamily="OpenSansSemiBold, OpenSans"
            fontWeight="600"
          >
            <tspan x="0" y="0">
              Host Name
            </tspan>
          </text>
        </g>
      </g>
      <g id="Group_2168" data-name="Group 2168" transform="translate(27)">
        <g id="Group_2160" data-name="Group 2160" transform="translate(-22)">
          <text
            id="Description-2"
            data-name="Description"
            transform="translate(707 368)"
            fontSize="18"
            fontFamily="OpenSansSemiBold, OpenSans"
            fontWeight="600"
          >
            <tspan x="0" y="0">
              Port
            </tspan>
          </text>
        </g>
      </g>
      <text
        id="Description-3"
        data-name="Description"
        transform="translate(807 368)"
        fontSize="18"
        fontFamily="OpenSansSemiBold, OpenSans"
        fontWeight="600"
      >
        <tspan x="0" y="0">
          Communication Key
        </tspan>
      </text>
      <text
        id="Created_By"
        data-name="Created By"
        transform="translate(1369 368)"
        fontSize="18"
        fontFamily="OpenSansSemiBold, OpenSans"
        fontWeight="600"
      >
        <tspan x="0" y="0">
          Version
        </tspan>
      </text>
      <text
        id="Modified_By"
        data-name="Modified By"
        transform="translate(1490 368)"
        fontSize="18"
        fontFamily="OpenSansSemiBold, OpenSans"
        fontWeight="600"
      >
        <tspan x="0" y="0">
          Creation Date
        </tspan>
      </text>
      <line
        id="Line_397"
        data-name="Line 397"
        x2="1584.124"
        transform="translate(287 435.5)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
        opacity="0.1"
      />
      <text
        id="Description-4"
        data-name="Description"
        transform="translate(1137 368)"
        fontSize="18"
        fontFamily="OpenSansSemiBold, OpenSans"
        fontWeight="600"
      >
        <tspan x="0" y="0">
          Enable Source Types
        </tspan>
      </text>
      <g id="Group_2187" data-name="Group 2187" transform="translate(26)">
        <text
          id="Modified_By-2"
          data-name="Modified By"
          transform="translate(1697 368)"
          fontSize="18"
          fontFamily="OpenSansSemiBold, OpenSans"
          fontWeight="600"
        >
          <tspan x="0" y="0">
            Status
          </tspan>
        </text>
      </g>
      <rect
        id="Rectangle_1516"
        data-name="Rectangle 1516"
        width="169"
        height="15"
        rx="7.5"
        transform="translate(311 401)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1524"
        data-name="Rectangle 1524"
        width="134"
        height="15"
        rx="7.5"
        transform="translate(529 401)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1526"
        data-name="Rectangle 1526"
        width="49"
        height="15"
        rx="7.5"
        transform="translate(712 401)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1528"
        data-name="Rectangle 1528"
        width="275"
        height="15"
        rx="7.5"
        transform="translate(807 401)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1530"
        data-name="Rectangle 1530"
        width="124"
        height="15"
        rx="7.5"
        transform="translate(1137 401)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1532"
        data-name="Rectangle 1532"
        width="60"
        height="15"
        rx="7.5"
        transform="translate(1369 401)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1534"
        data-name="Rectangle 1534"
        width="179"
        height="15"
        rx="7.5"
        transform="translate(1490 401)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1536"
        data-name="Rectangle 1536"
        width="30"
        height="15"
        rx="7.5"
        transform="translate(1723 401)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1522"
        data-name="Rectangle 1522"
        width="169"
        height="15"
        rx="7.5"
        transform="translate(311 455)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1523"
        data-name="Rectangle 1523"
        width="134"
        height="15"
        rx="7.5"
        transform="translate(529 455)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1525"
        data-name="Rectangle 1525"
        width="49"
        height="15"
        rx="7.5"
        transform="translate(712 455)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1527"
        data-name="Rectangle 1527"
        width="275"
        height="15"
        rx="7.5"
        transform="translate(807 455)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1529"
        data-name="Rectangle 1529"
        width="124"
        height="15"
        rx="7.5"
        transform="translate(1137 455)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1531"
        data-name="Rectangle 1531"
        width="60"
        height="15"
        rx="7.5"
        transform="translate(1369 455)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1533"
        data-name="Rectangle 1533"
        width="179"
        height="15"
        rx="7.5"
        transform="translate(1490 455)"
        fill="#f0f0f0"
      />
      <rect
        id="Rectangle_1535"
        data-name="Rectangle 1535"
        width="30"
        height="15"
        rx="7.5"
        transform="translate(1723 455)"
        fill="#f0f0f0"
      />
    </g>
  </svg>
);

export const ssoListBlankSlate = (
  <svg width="100%" height="100%" viewBox="0 0 936 229.5">
    <g id="SSO" transform="translate(-298.5 -358.5)">
      <line
        id="Line_453"
        data-name="Line 453"
        x2="936"
        transform="translate(298.5 587.5)"
        fill="none"
        stroke="#e2ebf4"
        strokeWidth="1"
      />
      <line
        id="Line_454"
        data-name="Line 454"
        x2="936"
        transform="translate(298.5 458.5)"
        fill="none"
        stroke="#e2ebf4"
        strokeWidth="1"
      />
      <g id="Group_2481" data-name="Group 2481">
        <rect
          id="Rectangle_1516"
          data-name="Rectangle 1516"
          width="197"
          height="15"
          rx="7.5"
          transform="translate(298.5 412)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_1521"
          data-name="Rectangle 1521"
          width="430"
          height="15"
          rx="7.5"
          transform="translate(571 412)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_1520"
          data-name="Rectangle 1520"
          width="176"
          height="20"
          rx="10"
          transform="translate(298.5 358.5)"
          fill="#f0f0f0"
        />
        <circle
          id="Ellipse_317"
          data-name="Ellipse 317"
          cx="12.5"
          cy="12.5"
          r="12.5"
          transform="translate(1117.5 362.5)"
          fill="#f0f0f0"
        />
        <circle
          id="Ellipse_318"
          data-name="Ellipse 318"
          cx="12.5"
          cy="12.5"
          r="12.5"
          transform="translate(1161.252 362.5)"
          fill="#f0f0f0"
        />
      </g>
      <g id="Group_2482" data-name="Group 2482" transform="translate(0 131)">
        <rect
          id="Rectangle_1516-2"
          data-name="Rectangle 1516"
          width="197"
          height="15"
          rx="7.5"
          transform="translate(298.5 412)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_1521-2"
          data-name="Rectangle 1521"
          width="430"
          height="15"
          rx="7.5"
          transform="translate(571 412)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_1520-2"
          data-name="Rectangle 1520"
          width="176"
          height="20"
          rx="10"
          transform="translate(298.5 358.5)"
          fill="#f0f0f0"
        />
        <circle
          id="Ellipse_317-2"
          data-name="Ellipse 317"
          cx="12.5"
          cy="12.5"
          r="12.5"
          transform="translate(1117.5 362.5)"
          fill="#f0f0f0"
        />
        <circle
          id="Ellipse_318-2"
          data-name="Ellipse 318"
          cx="12.5"
          cy="12.5"
          r="12.5"
          transform="translate(1161.252 362.5)"
          fill="#f0f0f0"
        />
      </g>
    </g>
  </svg>
);

export const accountSettingsInputBlankSlate = (
  <svg
    width="420"
    height="34"
    viewBox="0 0 420 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="#F0F0F0" d="M.84.5h587v43H.84z" />
    <path stroke="#C9C9C9" d="M.84.5h587v43H.84z" />
  </svg>
);
