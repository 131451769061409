const CustomizedAxisTick = (props: any): JSX.Element => {
  const { x, y, payload, textAnchor, fill, transform } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor={textAnchor}
        fill={fill}
        transform={transform}
      >
        <title>{payload?.value}</title>

        {payload?.value?.length > 15
          ? `${payload?.value?.slice(0, 15)}...`
          : payload?.value}
      </text>
    </g>
  );
};

export default CustomizedAxisTick;
