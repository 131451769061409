import { Card } from "antd";
import { PieChart as Chart, ResponsiveContainer, Pie, Tooltip } from "recharts";

import RechartHOC from "../recharthoc/recharthoc";

import { PIECHART_VALUE_DATA_KEY } from "../../../constants";

import { PieChartStyled } from "./piechart.styles";

import { RechartHOCEmbedProps } from "../recharthoc/recharthoc.types";
import { PieChartProps } from "./piechart.types";

import { numberFormatter, toFixed } from "../../../utils";

const RADIAN = Math.PI / 180;

const PieChartContent = (
  props: PieChartProps & RechartHOCEmbedProps
): JSX.Element => {
  const {
    width = 1000,
    height = 500,
    data = [],
    inActiveLegends,
    legends,
    ...res
  } = props;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }: any): JSX.Element => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${toFixed(percent * 100, 1)}%`}
      </text>
    );
  };

  return (
    <Card>
      <PieChartStyled>
        <ResponsiveContainer width={width} height={height}>
          <Chart data={data} {...res}>
            <Pie
              data={data?.filter(
                (item) => !inActiveLegends?.[item?.name?.toString() || ""]
              )}
              labelLine={false}
              label={renderCustomizedLabel}
              dataKey={PIECHART_VALUE_DATA_KEY}
            />
            {legends}
            <Tooltip formatter={(val): string => numberFormatter(`${val}`)} />
          </Chart>
        </ResponsiveContainer>
      </PieChartStyled>
    </Card>
  );
};

const PieChart = (props: PieChartProps): JSX.Element => (
  <RechartHOC
    dataKeys={props?.dataKeys}
    chartType="PIE"
    legendVerticalAlignment="middle"
  >
    <PieChartContent {...props} />
  </RechartHOC>
);

export default PieChart;
