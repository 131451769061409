//   <--Library Imports Start-->
import { useMemo } from "react";
//   <--Library Imports End-->

//   <--Custom Hooks Start-->
import {
  useCancelModal,
  useGetAppState,
  useGetAppType,
} from "../../customhooks";
//   <--Custom Hooks End-->

//   Utils Start-->
import {
  getCrossIcon,
  getModalContent,
  getModalHeaderHeight,
  getModalTitle,
  getModalWidth,
} from "./modalwrapper.utils";

import {
  FULL_SCREEN_MODAL_IDS,
  CENTERED_SCREEN_MODAL_IDS,
  FULL_SCREEN_MODAL_IDS_FOR_EXT_DKT,
} from "./modalwrapper.constants";

import { ModalStyled } from "./modalwrapper.styles";
//   Utils End-->

function ModalWrapper(): JSX.Element {
  const {
    modal: {
      modalTitle,
      visible,
      modalId = "delete_user_group",
      modalProps,
    } = {},
  } = useGetAppState();

  const { isExtOrDesktop } = useGetAppType();

  const onClose = useCancelModal();

  const isFullScreenModal = useMemo(
    () =>
      FULL_SCREEN_MODAL_IDS?.includes(modalId) ||
      (isExtOrDesktop && FULL_SCREEN_MODAL_IDS_FOR_EXT_DKT?.includes(modalId)),
    [modalId, isExtOrDesktop]
  );

  const isCenteredModal = useMemo(
    () => CENTERED_SCREEN_MODAL_IDS?.includes(modalId),
    [modalId]
  );

  const isCloseAble = useMemo(() => modalId !== "session_expired_modal", [
    modalId,
  ]);

  const isMaskCloseAble = useMemo(
    () => modalId === "dvsum_help_drawer_detail_modal",
    [modalId]
  );

  const zIndex = useMemo(() => {
    switch (modalId) {
      default:
        return 0;
    }
  }, [modalId]);

  return (
    <ModalStyled
      title={getModalTitle(modalId, modalTitle)}
      visible={visible}
      onCancel={onClose}
      width={getModalWidth(modalId, modalProps)}
      isFullScreenModal={isFullScreenModal}
      closeIcon={getCrossIcon(modalId)}
      centered={isCenteredModal}
      headerHeight={getModalHeaderHeight(modalId)}
      maskClosable={isMaskCloseAble}
      destroyOnClose
      isHideHeader={!modalTitle}
      isDialogBox={
        modalId === "confirmation_dialog_box" ||
        modalId === "delete_terms" ||
        !isFullScreenModal
      }
      {...(!modalProps?.animateModal && {
        transitionName: "",
        maskTransitionName: "",
      })}
      closable={isCloseAble}
      {...(zIndex && {
        zIndex,
      })}
    >
      {getModalContent(modalId)}
    </ModalStyled>
  );
}

export default ModalWrapper;
