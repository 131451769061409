export const RULE_SECTIONS = {
  status: "Status",
  definition: "Definition",
  description: "Description",
};

export const RULE_SECTION_TITLES = {
  STATUS: {
    RUN_STATUS: "Run Status",
    RUN_RESULT: "Run Result",
    WORKFLOW_STATUS: "Workflow Status",
    LAST_REFRESHED: "Last Refreshed",
    DQ_SCORE: "DQ Score",
    REMARKS: "Activity",
  },

  DEFINITION: {
    TABLE_NAME: "Table Name",
    REF_TABLE_NAME: "Reference Table",
    FIELDS_NAME: "Field Name(s)",
    FIELD_NAME: "Field Name",
    METRIC_NAME: "Metric Name",
    REFERENCE_DICTIONARY: "Reference dictionary",
    REF_FIELD_NAME: "Reference Field Name(s)",
    REF_METRIC_FIELD_NAME: "Reference Metric Field Name(s)",
    APPLIED_TO: "Applied To",
    ATTRIBUTE: "Attributes",
    SCHEDULED_JOB: "Scheduled Job",
    SCOPE: "Scope",
    RULE_TYPE: "Rule Type",
    THRESHOLD: "Thresholds",
    DATA_SOURCE: "Data Source",
    MIN_VALUE: "Min",
    MAX_VALUE: "Max",
    FILTER: "Filter",
    DATA_DIFF_CHECK: "Data Diff check",
    SOURCE_SCHEMA: "Source Schema",
    TARGET_SCHEMA: "Target Schema",
    THRESHOLD_HOURS: "Threshold(hours)",
  },

  DESCRIPTION: "Description",

  RULE_DEFINITION: {
    COL_ID: "Column",
    RULE_DESC: "Rule Description",
    REF_SRC_ID: "Reference",
    // ref_tbl_id: number;
    // metric_type: string;
    // attr_col_ids: number;
    // metric_col_ids: number;
    // ref_attr_col_ids: number;
    // ref_metric_col_ids: number;
    // compare_type: string;
    // variance_pct: number;
    // threshold_hrs: number;
    // col_ids: number[];
    // ref_col_ids: number;
    // join_type: string;
    // ref_additional_cols: number;
    // data_type: string;
    // min_length: number;
    // max_length: number;
    // max_precision: number;
    // is_whitespaces_allowed: boolean;
    // rule_type: string;
    // column: string;
    // specify: RangeType;
    // define: string;
    // select_values: string[];
    // specify_fields: Array<RefDictSpecifyFieldsType>;
    // dictionary?: string;
    // min_column_id?: string;
    // max_column_id?: string;
    // ignore_case?: boolean;
    // include_blanks?: boolean;
    // is_stored_proc: boolean;
    // custom_query: string;
    // range_type: RangeType;
    // is_single_value: boolean;
    // min_value: string;
    // max_value: string;
    // min_oper?: OperatorType;
    // max_oper?: OperatorType;
    // min_value_type?: ValueType;
    // max_value_type?: ValueType;
    // ref_dict_id?: number;
    // value_list?: string[];
    // min_col_id?: number;
    // max_col_id?: number;
    // match_case?: boolean;
    // is_selection_valid: boolean;
    // is_blanks_included?: boolean;
    // min_expr?: string;
    // max_expr?: string;
    // sql_stmt?: string;
    // ref_dict_map: Array<{
    //   col_id: number;
    //   rd_field_name: string;
    //   order: number;
    // }>;
  },
};

export const RULE_SECTION_SEQUENCE = {
  STATUS: {
    RUN_STATUS: 1,
    LAST_REFRESHED: 2,
    DQ_SCORE: 3,
    WORKFLOW_STATUS: 4,
    REMARKS: 5,
    RUN_RESULT: 2,
  },

  DEFINITION: {
    RULE_TYPE: 1,
    APPLIED_TO: 2,
    ATTRIBUTE: 3,
    SCHEDULED_JOB: 4,
    SCOPE: 5,
    THRESHOLD: 6,
  },

  DESCRIPTION: "Description",
};
