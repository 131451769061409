import { Tooltip } from "antd";
import { useController } from "react-hook-form";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../constants";
import { CheckboxFieldStyled } from "./checkboxfield.styles";
import { CheckboxFieldProps } from "./checkboxfield.types";

function CheckboxField({
  name = "",
  control,
  children,
  tooltipProps = { title: "" },
  disabled,
  ...props
}: CheckboxFieldProps): JSX.Element {
  const { overlayClassName } = tooltipProps;

  const {
    field: { ref, value, ...checkboxProps },
  } = useController({ name, control });
  return (
    <Tooltip
      overlayClassName={`${DVSUM_TOOLTIP_CLASS_NAME} ${overlayClassName ?? ""}`}
      {...tooltipProps}
    >
      <CheckboxFieldStyled
        disabled={disabled}
        {...props}
        {...checkboxProps}
        onChange={(e): void => {
          props?.onChange?.(e);
          checkboxProps?.onChange?.(e);
        }}
        ref={ref}
        checked={value}
      >
        {children}
      </CheckboxFieldStyled>
    </Tooltip>
  );
}

export default CheckboxField;
