import CoreNodesCircleIcon from "../corenodetypescircleicon";
import {
  DropdownCategoryWrapper,
  HeaderDropdownItemStyled,
} from "./searchwithdropdown.styles";
import {
  MenuSingleItemProps,
  MenuSingleItemType,
} from "./searchwithdropdown.types";

export const renderTitle = (title: string): JSX.Element => {
  return (
    <DropdownCategoryWrapper className="dropdown-wrapper">
      {title}
    </DropdownCategoryWrapper>
  );
};

export const renderItem = (props: MenuSingleItemProps): MenuSingleItemType => ({
  value: `${props.key}`,
  label: (
    <HeaderDropdownItemStyled key={props.key} className="dropdown-item-wrapper">
      <span className="search-node-icon">
        {props?.icon || (
          <CoreNodesCircleIcon
            nodeType={props?.nodeType || "ATD"}
            nodeSubType={props?.nodeSubType}
            width="18px"
            height="18px"
            fontSize={props?.nodeType === "ANL" ? "9px" : "11px"}
          />
        )}
      </span>
      <span
        className={`${
          props?.isSearchText ? "search-text" : "search-node-info"
        }`}
      >
        <div title={props?.title}>{props?.title}</div>
        {props?.subText && (
          <div className="dropdown-item-subtext" title={props?.subText}>
            • {props?.subText}
          </div>
        )}
      </span>
    </HeaderDropdownItemStyled>
  ),
  route: props?.route,
  key: props?.key,
});
