//   <--Components Start-->
import { Button } from "../../..";
import { FormItemStyled, FormStyled } from "../../../form";
//   <--Components End-->

//   <--Custom Hooks Start-->
import { useCancelModal, useGetAppState } from "../../../../customhooks";
//   <--Custom Hooks End-->

//   <--Styles Start-->
import { QuickEditForColConfirmationModalStyled } from "./quickeditforcolconfirmationmodal.styles";
//   <--Styles End-->

//   <--Types Start-->
import { QuickEditForColConfirmationModalProps } from "./quickeditforcolconfirmationmodal.types";
//   <--Types End-->

const QuickEditForColConfirmationModal = (): JSX.Element => {
  const onCancel = useCancelModal();

  const { modal } = useGetAppState();

  const { onEditEntity, onEditField } = (modal?.modalProps ||
    {}) as QuickEditForColConfirmationModalProps;

  return (
    <QuickEditForColConfirmationModalStyled>
      <div className="content-sec">
        This field is linked to logical business entity. If you edit the
        definition, it might be inconsistent with the logical common definition.
        If the entity definition is correct, we suggest you to edit the logical
        definition.
      </div>
      <FormStyled>
        <FormItemStyled className="form-actions-sec">
          <Button id="cancel" onClick={onCancel}>
            Cancel
          </Button>
          <Button marginLeft="10px" onClick={onEditEntity}>
            Edit Entity
          </Button>
          <Button marginLeft="10px" onClick={onEditField}>
            Edit Field
          </Button>
        </FormItemStyled>
      </FormStyled>
    </QuickEditForColConfirmationModalStyled>
  );
};
export default QuickEditForColConfirmationModal;
