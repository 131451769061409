import styled from "styled-components";
import { hexToRgbA } from "../../../utils";
import { LineChartStyledProps } from "./linechart.types";

export const LineChartStyled = styled.div<LineChartStyledProps>`
  pointer-events: all;
  width: ${({ width }): string => width || "805px"};
  height: ${({ height }): string => height || "500px"};
  background: ${({ theme }): string =>
    hexToRgbA(theme.bgColors.LIGHT_BLUE, 0.5)};
  padding: 40px 20px 12px 20px;
  position: relative;

  .line-chart-collapse-btn {
    position: absolute;
    right: 12px;
    top: 12px;
  }
`;
