//     <--Library Import Start-->
import { format } from "date-fns";
//     <--Library Import End-->

//     <--BlankSlates Start-->
import { tableRecordCountHistoryChartBlankSlate } from "../../blankslates";
//     <--BlankSlates End-->

//     <--Components Start-->
import { LineChart } from "../charts";
import StateHandler from "../statehandler";
//     <--Components End-->

//     <--Service Start-->
import { useGetTableRecordCountHistory } from "../../api/columnsservice";
//     <--Service End-->

//     <--Styles Start-->
import { TableRecordCountHistoryChartStyled } from "./tablerecordcounthistorychart.styles";
//     <--Styles End-->

//     <--Types Start-->
import { TableRecordCountHistoryChartProps } from "./tablerecordcounthistorychart.types";
//     <--Types End-->

//     <--Utils Start-->
import { numberFormatter } from "../../utils";
//     <--Utils End-->

const TableRecordCountHistoryChart = (
  props: TableRecordCountHistoryChartProps
): JSX.Element => {
  const {
    tableId = "",
    width = "740px",
    height = "",
    blankSlateWidth = "",
  } = props;

  const { parsedData = [], isLoading, error } = useGetTableRecordCountHistory(
    tableId
  );

  return (
    <TableRecordCountHistoryChartStyled>
      <StateHandler
        isFetching={isLoading}
        error={error}
        blankSlate={tableRecordCountHistoryChartBlankSlate(blankSlateWidth)}
      >
        <LineChart
          data={parsedData}
          xAxis={{
            tickFormat: (val: number): string => format(new Date(val), "P"),
            tickTotal: 4,
            left: 0,
          }}
          width={width}
          legends={false}
          height={height}
          crosshair={{
            yFormatter: (val): string => `${numberFormatter(`${val}`)}`,
          }}
        />
      </StateHandler>
    </TableRecordCountHistoryChartStyled>
  );
};

export default TableRecordCountHistoryChart;
