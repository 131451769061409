import Sider from "antd/lib/layout/Sider";
import styled, { css } from "styled-components";

const selectedItemCss = css`
  ::after {
    display: block;
    content: "";
    position: absolute;
    right: 0;
    border: 8px solid transparent;
    border-right-color: #fff;
    border-width: 20px 14px;
    top: 2px;
  }
`;

export const NavBarStyle = styled.div<{
  isFromChatPage?: boolean;
}>`
  &.chat-navbar-style {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .menu-container {
    background: ${(props): string => props.theme.bgColors.GREY_40};
    font-size: 12px;

    &:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: transparent;

      svg {
        path {
          fill: ${(props): string =>
            props.theme.bgColors.LIGHT_BLUE_20} !important;
        }
      }

      a,
      span {
        color: ${(props): string => props.theme.textColors.BLUE_30} !important;
      }
    }

    .ant-menu-title-content {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      svg {
        path {
          fill: ${(props): string => props.theme.textColors.GREY_80};
        }
        width: 20px;
        height: 16px;
        vertical-align: bottom;
      }
    }

    .ant-menu-item-selected {
      ${selectedItemCss}

      :first-of-type {
        ::after {
          border-right-color: #f0f0f0;
          top: 0;
        }
      }
    }

    .sub-menu-container {
      &:not(.ant-menu-submenu) {
        background-color: transparent;
        padding: 0 16px 0 7px;
        padding-left: 7px !important;
        border-left: 2px solid
          ${(props): string => props.theme.borderColors.GREY_40};
        border-color: ${(props): string => props.theme.borderColors.GREY_40};
        margin: 0px;
        height: ${({ isFromChatPage }): string =>
          isFromChatPage ? "unset" : "45px"};
        border-bottom: 1px solid
          ${(props): string => props.theme.borderColors.GREY_175};

        a {
          font-family: OpenSans;

          color: ${(props): string => props.theme.textColors.GREY_80};
        }

        &:hover {
          border-left: 2px solid rgb(133, 192, 236);
          background: ${(props): string => props.theme.bgColors.GREY_30};

          a {
            color: ${(props): string => props.theme.textColors.BLUE_20};
          }
        }

        .ant-menu-submenu-title {
          margin: 0px;
        }
      }

      &.add-new-chat-btn-container {
        display: flex;
        align-items: center;
        border-radius: 5px;

        svg {
          width: 20px;
          height: 10px;

          path {
            fill: ${(props): string => props.theme.textColors.WHITE_COLOR};
          }
        }
      }
      .icon-after-title {
        svg {
          width: 7px;
          height: 7px;
        }
      }
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        padding: 0 16px 0 7px;
        padding-left: 7px !important;
        border-left: 2px solid
          ${(props): string => props.theme.borderColors.GREY_40};
        border-color: ${(props): string => props.theme.borderColors.GREY_40};
        margin: 0px;
        height: 45px;
        border-bottom: 1px solid
          ${(props): string => props.theme.borderColors.GREY_175};
        color: ${(props): string => props.theme.textColors.GREY_80};

        :hover {
          border-left: 2px solid rgb(133, 192, 236);
          background: ${(props): string => props.theme.bgColors.GREY_30};
          color: ${(props): string => props.theme.textColors.BLUE_20};
        }
      }

      .ant-menu-sub {
        font-size: 13px;
        position: relative;
        max-height: calc(100vh - 490px);
        overflow-y: auto;

        .ant-menu-item {
          padding: 7px 4px 9px 43px;
          padding-left: 38.5px !important;
          height: 45px;
          background: ${(props): string => props.theme.bgColors.GREY_30};
          margin: 0px;

          :first-of-type {
            border-top: 1px solid
              ${(props): string => props.theme.borderColors.GREY_20};
          }
          :not(:last-of-type) {
            border-bottom: 1px dotted
              ${(props): string => props.theme.borderColors.LIGHT_GREY_20};
          }
          :last-of-type {
            border-top: 1px solid
              ${(props): string => props.theme.borderColors.GREY_20};
          }

          a {
            font-size: 12px;
            font-family: OpenSans;
            color: ${(props): string => props.theme.textColors.WHITE_COLOR};
          }

          :hover {
            span,
            a {
              color: ${(props): string => props.theme.textColors.LIGHT_BLUE_20};
            }
          }
          ${selectedItemCss}
        }

        .ant-menu-item-selected {
          a {
            color: ${(props): string => props.theme.textColors.LIGHT_BLUE_20};
          }
        }
      }
    }

    .menu-container:not(&.ant-menu-vertical) {
      .ant-menu-item,
      .ant-menu-submenu-title {
        svg {
          path {
            fill: ${(props): string => props.theme.bgColors.GREY_80};
          }
        }

        :hover {
          svg {
            path {
              fill: ${(props): string => props.theme.bgColors.LIGHT_BLUE_20};
            }
          }
        }
      }

      .ant-menu-submenu-open {
        .ant-menu-submenu-title {
          background: ${(props): string => props.theme.bgColors.GREY_40};
          color: ${(props): string => props.theme.textColors.LIGHT_BLUE_20};
          svg {
            path {
              fill: ${(props): string => props.theme.bgColors.LIGHT_BLUE_20};
            }
          }
          &:hover {
            background: ${(props): string => props.theme.bgColors.GREY_40};
            color: ${(props): string => props.theme.textColors.LIGHT_BLUE_20};
            svg {
              path {
                fill: ${(props): string => props.theme.bgColors.LIGHT_BLUE_20};
              }
            }
          }
        }
      }

      .ant-menu-submenu {
        .ant-menu-sub {
          ::before {
            content: "";
            display: block;
            position: absolute;
            z-index: 1;
            left: 18px;
            top: 0;
            bottom: 0;
            border: 1px dotted
              ${(props): string => props.theme.borderColors.GREY_60};
            border-width: 0 0 0 1px;
            height: 584px;
          }

          .ant-menu-item {
            ::before {
              content: "";
              display: inline-block;
              position: absolute;
              width: 12px;
              left: 20px;
              top: 48%;
              border-top: 1px dotted
                ${(props): string => props.theme.borderColors.GREY_60};
            }
          }
        }
      }
    }

    &.ant-menu-vertical {
      .ant-menu-submenu-vertical {
        position: relative;

        .ant-menu-submenu-title {
          line-height: 30px;
        }

        .ant-menu-submenu-popup {
          top: 0 !important;
          left: 43px !important;

          .ant-menu-sub {
            .ant-menu-item {
              background: ${(props): string => props.theme.bgColors.GRYEY_65};
              display: flex;
              align-items: center;
              padding-left: 24px !important;

              :first-of-type {
                padding-left: 15px !important;
                padding-right: 15px;
                color: ${(props): string => props.theme.textColors.BLUE_20};
                background: ${(props): string => props.theme.bgColors.GREY_30};
                height: 45px;

                :hover {
                  .ant-menu-submenu-title {
                    display: none;
                  }
                }
              }

              :hover {
                :first-of-type {
                  color: ${(props): string => props.theme.textColors.BLUE_20};
                }
              }
            }
          }
        }
      }

      .ant-menu-submenu-open {
        .ant-menu-submenu-title {
          background: ${(props): string => props.theme.bgColors.GREY_30};

          svg {
            path {
              fill: ${(props): string => props.theme.bgColors.BLUE_20};
            }
          }
        }
      }
      .ant-menu-submenu-selected {
        .ant-menu-submenu-title {
          background: ${(props): string => props.theme.bgColors.GREY_40};
          svg {
            path {
              fill: ${(props): string => props.theme.bgColors.LIGHT_BLUE_20};
            }
          }
          :hover {
            background: ${(props): string => props.theme.bgColors.GREY_40};
          }
        }
      }
    }
  }

  .side-handler-container {
    background: ${(props): string => props.theme.bgColors.GREY_150};
    padding: 3px 10px;
    position: relative;
    border-bottom: 1px solid
      ${(props): string => props.theme.borderColors.GREY_175};
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn-content-container {
      text-align: center;

      .snv-handler-btn {
        min-width: 20px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: ${(props): string => props.theme.textColors.GREY_45};
        background: ${(props): string => props.theme.bgColors.GREY_40};
        font-size: 14px;
        border: 1px solid ${(props): string => props.theme.borderColors.GREY_55};

        > svg {
          width: 8px;
          height: 16px;
        }
      }
    }
    &:before {
      content: "";
      display: inline-block;
      height: 0;
      border-top: 1px solid
        ${(props): string => props.theme.borderColors.GREY_175};
      position: absolute;
      left: 15px;
      right: 15px;
      top: 12px;
    }

    &.chat-handler {
      &.ant-layout {
        flex: unset;
      }
    }
  }

  .sidenav-gateway-item,
  .sidenav-gateway-item-collapsed {
    display: flex;

    .gateways-list-popover {
      height: 45px;
      justify-content: flex-start;
      column-gap: 8px;
      padding-left: 7px;
      font-size: 14px;
      font-weight: 400;
      color: ${(props): string => props.theme.textColors.GREY_45};
      border-left: none;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    :hover {
      background: #414b51;
    }

    border-bottom: 1px solid #3f4e54;
  }

  .sidenav-gateway-item-collapsed {
    .gateways-list-popover-container {
      width: unset;
      .gateways-list-popover {
        width: unset;
        padding-left: 6px;
      }
    }
  }
`;

export const SiderStyled = styled(Sider)<{
  isCollapsed: boolean;
  isFromChatPage?: boolean;
}>`
  position: fixed;
  top: ${({ isFromChatPage }): string => (isFromChatPage ? "0" : "45px")};
  left: 0;
  z-index: 10;
  min-height: calc(100vh - 57px);
  height: 100%;
  width: ${(props): string =>
    props.isCollapsed ? "43px" : "190px"} !important;
  min-width: ${(props): string =>
    props.isCollapsed ? "43px" : "190px"} !important;
  flex: 0 !important;
  background: ${(props): string => props.theme.bgColors.GREY_40};
`;
