import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setModal } from "../reducers/appreducer/appreducer";

import { AppState } from "../reducers/appreducer/appreducer.types";

export function useOpenModal(): (_modal: AppState["modal"]) => void {
  const dispatch = useDispatch();

  return useCallback((modal: AppState["modal"]) => {
    dispatch(setModal(modal));
  }, []);
}
