import { useMemo } from "react";
import { Card } from "antd";

import {
  BarChart as Chart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";

import RechartHOC from "../recharthoc/recharthoc";
import { ColumnChartStyled } from "./column.styles";

import { RechartHOCEmbedProps } from "../recharthoc/recharthoc.types";
import { ColumnChartProps } from "./columnchart.types";

import { numberFormatter } from "../../../utils";
import { X_AXIS_DATA_KEY } from "../../../constants";

import { DEFAULT_MARGIN } from "../defaultmargin";
import { DEFAULT_X_AXIS_LABEL } from "../defaultxaxislabel";

import { DEFAULT_Y_AXIS_LABEL } from "../defaultyaxislabel";
import CustomizedAxisTick from "../customizedaxistick";
import { getYAxisMaxValWithNearestRoundedVal } from "../rechart.utills";

const ColumnChartContent = (
  props: ColumnChartProps & RechartHOCEmbedProps
): JSX.Element => {
  const {
    width = "100%",
    height = 500,
    data = [],
    clonedDataKeys = [],
    xAxislabel,
    legends,
    margin,
    isStacked,
    updatedYAxis,
    isMultipleYAxis,
    isTiltedXAxisTicks = false,
    hideXAxis = false,
    showCartesianGrid = true,
    hideYAxis = false,
    keepStaicBarSize = true,
    onClick,
    onDomainLoad,
    chartYAxisLeftOffset = 0,
    chartInnerSpaceFromLeft = 30,
    tooltipFormatter,
    yAxisFormatter,
    showYAxisLabels = true,
    footerComponent,
    xAxisTickFormatter,
    tooltipLabelFormatter,
  } = props;

  return (
    <Card>
      <ColumnChartStyled>
        <ResponsiveContainer width={width} height={height}>
          <Chart
            className="column-chart"
            data={data}
            margin={{
              ...DEFAULT_MARGIN,
              left: chartYAxisLeftOffset - chartInnerSpaceFromLeft,
              ...margin,
            }}
            {...(!keepStaicBarSize && { barCategoryGap: 0.1 })}
            onClick={onClick}
          >
            {showCartesianGrid && (
              <CartesianGrid
                strokeDasharray="1"
                vertical={false}
                stroke="#e6e6e6"
              />
            )}
            <XAxis
              dataKey={X_AXIS_DATA_KEY}
              stroke="#8a8c8c"
              axisLine={false}
              tickLine={false}
              height={60}
              hide={hideXAxis}
              tick={
                isTiltedXAxisTicks ? (
                  <CustomizedAxisTick
                    textAnchor="end"
                    fill="#8A8C8C"
                    transform="rotate(-20)"
                  />
                ) : (
                  { fill: "#8A8C8C" }
                )
              }
              tickFormatter={xAxisTickFormatter}
            >
              {xAxislabel && (
                <Label {...{ ...DEFAULT_X_AXIS_LABEL, ...xAxislabel }} />
              )}
            </XAxis>
            {updatedYAxis?.map((item) => (
              <YAxis
                key={`column-chart-y-axis-${item?.yAxisId}`}
                axisLine={false}
                tickLine={false}
                hide={hideYAxis || item?.hide}
                orientation={item?.orientation}
                stroke="#8a8c8c"
                tickFormatter={(val): string => {
                  onDomainLoad?.(val);
                  return `${item?.prefix || ""}${
                    yAxisFormatter?.(val, item?.yAxisId || "") ||
                    numberFormatter(val)
                  }${item?.suffix || ""}`;
                }}
                {...(isMultipleYAxis &&
                  !isStacked && {
                    ...item,
                    label: undefined,
                  })}
                {...(item?.hide
                  ? {
                      domain: ([min, max]): [number, number] => {
                        const minValue = item?.minYDomain || min || 0;
                        const maxVAlue = item?.maxYDomain || max || 0;

                        const finiteMinValue = Number.isFinite(minValue)
                          ? minValue
                          : 0;

                        const finiteMaxValue = Number.isFinite(maxVAlue)
                          ? maxVAlue
                          : 0;

                        const maxValueWithBuffer = getYAxisMaxValWithNearestRoundedVal(
                          finiteMaxValue
                        );

                        return [finiteMinValue, maxValueWithBuffer];
                      },
                    }
                  : /* ! operator is being used with minYDomain because it won't have
                    any impact on current implementation even if minYDomain is undefined
                    it can be changed in future. */
                    {
                      domain: [
                        item?.minYDomain! >= 0 ? 0 : item?.minYDomain || "auto",
                        item?.maxYDomain! <= 0 ? 0 : item?.maxYDomain || "auto",
                      ],
                    })}
              >
                {item?.label && (
                  <Label
                    {...{
                      ...DEFAULT_Y_AXIS_LABEL,
                      ...item?.label,
                      value: showYAxisLabels ? item?.label?.value : "",
                    }}
                  />
                )}
              </YAxis>
            ))}

            <Tooltip
              formatter={(val, name): string => {
                const prefix =
                  updatedYAxis?.find((item) => item?.yAxisId === name)
                    ?.prefix || "";

                const suffix = `${
                  updatedYAxis?.find((item) => item?.yAxisId === name)
                    ?.suffix || ""
                }`;

                return `${prefix}${
                  tooltipFormatter?.(Number(val), `${name}`) ||
                  numberFormatter(`${val}`, true)
                }${suffix}`;
              }}
              labelFormatter={tooltipLabelFormatter}
              separator=": "
            />
            {legends}
            {clonedDataKeys?.map((key) => (
              <Bar
                {...key}
                {...(keepStaicBarSize && { barSize: 60 })}
                key={`columnchart-datakey-${key?.dataKey}`}
                {...(isMultipleYAxis &&
                  !isStacked && { yAxisId: key?.dataKey?.toString() })}
                {...(isStacked && { stackId: "a" })}
                minPointSize={2}
              />
            ))}
          </Chart>
        </ResponsiveContainer>
        {footerComponent}
      </ColumnChartStyled>
    </Card>
  );
};

const ColumnChart = (props: ColumnChartProps): JSX.Element => {
  return (
    <RechartHOC
      dataKeys={props?.dataKeys}
      yAxis={props?.yAxis}
      showLedgends={props?.showLedgends}
    >
      <ColumnChartContent {...props} />
    </RechartHOC>
  );
};
export default ColumnChart;
