import {
  CreateListForm,
  AddUsersGroupForm,
  DeleteUserGroupForm,
  DiscardTableDetailChanges,
  OverviewSuggestAnEdit,
  OverviewCreateSuggestion,
  AddSAWSForm,
  AddSSOForm,
  MarkAsDeprecateSuggestionForm,
  ProductTourForm,
  AddGlossaryDomainForm,
  DeleteGlossaryDomainForm,
  DeleteGlossaryCategoryForm,
  AddGlossaryCategoryForm,
  AddTermForm,
  AddGovernanceViewForm,
  MassUpdateForm,
  ShareViewerForm,
  ShareOwnerForm,
  AddTagsetForm,
  MergeTagsForm,
  DeleteTagsForm,
  UpdateTags,
  TrainbotShareConceptForm,
} from "../../forms";

import AddUserRoleform from "../../forms/addroleform/addroleform";

import AddSourceForm from "../../forms/addsourceform/addsourceform";
import AddUserForm from "../../forms/adduserform/adduserform";

import DeactivateUserForm from "../../forms/deactivateuserform/deactivateuserform";
import DeleteSourceForm from "../../forms/deletesourceform/deletesourceform";

import DeleteUserForm from "../../forms/deleteuserform/deleteuserform";
import DeleteUserRoleForm from "../../forms/deleteuserroleform/deleteuserroleform";

import SaveSourceForm from "../../forms/savesourceform/savesourceform";
import AddSourceFieldForm from "../../forms/addsourceform/addsourcefieldsform/addsourcefieldsform";

import EnableSourceForm from "../../forms/enablesourceform/enablesourceform";

import ReviewRecomendedEntityForm from "../../forms/reviewrecomendedentitymodal";
import GatewayInstallerModalBody from "../gatewayinstallermodalbody/gatewayinstallermodalbody";

import DialogBox from "../dialogbox";
import {
  DeleteTerm,
  DeleteRecords,
  DataClassification,
} from "../../pages/listingpage/listingpage.components";

import { DeleteExcelSourceFile } from "../../pages/sourcedetailpage/tabs/excelsourcefiles/excelsourcefiles.components";
import SessionExpiredModal from "../sessionexpiredmodal";

import DvsumHelpDrawerDetailModal from "./modals/dvsumhelpdrawerdetailmodal/dvsumhelpdrawerdetailmodal";
import InformationModal from "../informationmodal/informationmodal";

import ConnectionSettingsDynamicModal from "../../pages/sourcedetailpage/tabs/settingstab/tabs/connectionsettingstab/connectionsettingsdynamicmodal/connectionsettingsdynamicmodal";
import {
  ResendInviteUserModal,
  DQRuleDetailsModal,
  DataImportSubmittedModal,
  SSASaveAnalysisModal,
  SSAAddQueryModal,
  EditPromptModal,
  CancelScanModal,
  MyPreferencesModal,
  ColumnSequenceModal,
  UpdateScheduleModal,
  RemoveScheduleModal,
  ResolveModal,
  TrainBotFeedbackModal,
  ReviewCaddiFeedbacksModal,
} from "./modals";

import QuickEditForColConfirmationModal from "./modals/quickeditforcolconfirmationmodal/quickeditforcolconfirmationmodal";

import {
  ModalFullScreenTitleStyled,
  MediumModalTitleStyled,
} from "./modalwrapper.styles";

import { crossIcon } from "../../svgs";

import { AddUserRoleFormStyled } from "../../forms/addroleform/addroleform.styles";
import { AppState } from "../../reducers/appreducer/appreducer.types";
import CloneRuleSummaryModal from "./modals/clonerulesummarymodal/clonerulesummarymodal";
import CancelProfilingModal from "./modals/cancelprofilingmodal/cancelprofilingmodal";
import CloneRuleModal from "./modals/clonerulemodal/clonerulemodal";

import AddRuleForm from "../../forms/addruleform";
import AddNewJobForm from "../../forms/addnewjobform/addnewjobform";

import CancelScheduleModal from "./modals/cancelschedulemodal/cancelschedulemodal";
import {
  DiscardConfirmation,
  DeleteDictionary,
} from "../../pages/referencedictionary/referencedictionary.components";

import AddReferenceDictionary from "./modals/addreferencedictionary/addreferencedictionary";

export function getModalWidth(
  id: AppState["modal"]["modalId"],
  modalProps: AppState["modal"]["modalProps"]
): string {
  switch (id) {
    case "add_user_group":
    case "edit_user_group":
    case "add_user_role":
    case "edit_user_role":
    case "add_user":
    case "edit_user":
    case "add_sso":
    case "edit_sso":
    case "add_glossary_domain":
    case "edit_glossary_domain":
    case "add_glossary_category":
    case "edit_glossary_category":
    case "add_governance_view":
    case "edit_governance_view":
    case "clone_governance_view":
    case "rule_type_detail_modal":
    case "add_rule":
      return "100vw";
    case "delete_user_group":
    case "delete_user_role":
    case "activate_user":
    case "deactivate_user":
    case "delete_user":
    case "delete_glossary_domain":
    case "delete_glossary_category":
      return "617px";
    case "discard_table_detail_changes":
    case "ssa_discard_analysis":
    case "delete_relationship_modal":
      return "577px";
    case "suggest_an_edit_form":
      return modalProps?.isFullScreen ? "100%" : "441px";
    case "create_suggestion_form":
      return modalProps?.isFullScreen ? "100%" : "791px";
    case "mark_as_deprecate_suggestion":
      return modalProps?.isFullScreen ? "100%" : "771px";
    case "select_source":
      return "920px";
    case "add_source":
    case "add_source_fields":
    case "add_term":
    case "add_table":
    case "share_owner":
    case "add_tagset":
      return "720px";
    case "merge_tags":
    case "delete_tags":
      return "888px";
    case "enable_source":
      return "920px";
    case "delete_source":
    case "logout_confirmation_box":
      return "536px";
    case "save_source":
      return "462px";
    case "confirmation_dialog_box":
    case "delete_terms":
      return "606px";
    case "datasource_discard_confirmation":
      return "462px";
    case "download_gateway_installer_dialog":
      return "fit-content";
    case "product_tour":
      return "612px";

    case "listing_page_add_tags":
    case "listing_page_remove_tags":
      return "548px";
    case "mass_update":
      return "653px";
    case "connection_settings_dynamic_modal":
      return modalProps?.width || "fit-content";
    case "share_viewer":
      return "400px";
    case "lineage_share_viewer":
      return "400px";
    case "session_expired_modal":
      return "403px";
    case "delete_excel_source_file":
      return "461px";
    case "review_recomended_entity_modal":
    case "add_recomended_entity_modal":
      return "925px";
    case "re_send_invite_to_user":
      return "606px";
    case "quick_edit_for_col_confirmation":
      return "509px";
    case "data_import_submitted_modal":
    case "cancel_scan_modal":
      return "509.6px";
    case "dvsum_help_drawer_detail_modal":
      return "719px";
    case "delete_analysis_query":
      return "506px";
    case "ssa_save_analysis_modal":
      return "754px";
    case "edit_prompt":
      return "85%";
    case "train_bot_feedback_modal":
      return "100vw";
    case "train_bot_share_concept_modal":
      return "100vw";
    case "my_preferences_modal":
    case "clone_rule_summary_modal":
    case "add_new_job_modal":
      return "100vw";
    case "review_caddi_feedbacks_modal":
      return "100vw";
    case "filter_condition_modal":
      return "65vw";

    case "delete_records":
    case "delete_dictionary":
    case "cancel_profiling_modal":
      return "600px";
    case "discard_dictionary_confirmation":
      return "480px";

    case "update_schedule_modal":
    case "remove_schedule_modal":
    case "data_classification":
      return "600px";

    case "cancel_schedule":
      return "600px";

    case "clone_rule_modal":
      return "850px";

    case "resolve_modal":
      return "700px";

    case "column_sequence_modal":
      return "653px";
    default:
      return "771px";
  }
}

export function getModalHeaderHeight(id: AppState["modal"]["modalId"]): string {
  switch (id) {
    default:
      return "56px";
  }
}

export function getModalContent(id: AppState["modal"]["modalId"]): JSX.Element {
  switch (id) {
    case "create_list_form":
      return <CreateListForm />;
    case "add_user_group":
      return <AddUsersGroupForm />;
    case "edit_user_group":
      return <AddUsersGroupForm isEditMode />;
    case "delete_user_group":
      return <DeleteUserGroupForm />;
    case "discard_table_detail_changes":
      return <DiscardTableDetailChanges />;
    case "create_suggestion_form":
      return <OverviewCreateSuggestion />;
    case "suggest_an_edit_form":
      return <OverviewSuggestAnEdit />;
    case "add_user_role":
      return <AddUserRoleform />;
    case "edit_user_role":
      return <AddUserRoleform isEditMode />;
    case "delete_user_role":
      return <DeleteUserRoleForm />;
    case "add_user":
      return <AddUserForm />;
    case "edit_user":
      return <AddUserForm isEditMode />;
    case "delete_user":
      return <DeleteUserForm />;
    case "deactivate_user":
    case "activate_user":
      return <DeactivateUserForm />;
    case "select_source":
    case "add_source":
      return <AddSourceForm />;
    case "add_source_fields":
      return <AddSourceFieldForm />;
    case "delete_source":
      return <DeleteSourceForm />;
    case "save_source":
      return <SaveSourceForm />;
    case "confirmation_dialog_box":
    case "datasource_discard_confirmation":
    case "logout_confirmation_box":
    case "import_fields_mapping_confirmation_modal":
    case "delete_analysis_query":
    case "ssa_discard_analysis":
    case "delete_relationship_modal":
      return <DialogBox />;
    case "add_saws":
      return <AddSAWSForm />;
    case "edit_saws":
      return <AddSAWSForm isEditMode />;
    case "add_sso":
      return <AddSSOForm />;
    case "edit_sso":
      return <AddSSOForm isEditMode />;
    case "mark_as_deprecate_suggestion":
      return <MarkAsDeprecateSuggestionForm />;
    case "enable_source":
      return <EnableSourceForm />;
    case "download_gateway_installer_dialog":
      return <GatewayInstallerModalBody />;
    case "product_tour":
      return <ProductTourForm />;
    case "add_glossary_domain":
      return <AddGlossaryDomainForm />;
    case "edit_glossary_domain":
      return <AddGlossaryDomainForm isEditMode />;
    case "delete_glossary_domain":
      return <DeleteGlossaryDomainForm />;
    case "add_glossary_category":
      return <AddGlossaryCategoryForm />;
    case "edit_glossary_category":
      return <AddGlossaryCategoryForm isEditMode />;
    case "delete_glossary_category":
      return <DeleteGlossaryCategoryForm />;
    case "add_term":
      return <AddTermForm />;
    case "add_governance_view":
      return <AddGovernanceViewForm />;
    case "edit_governance_view":
      return <AddGovernanceViewForm isEditMode />;
    case "clone_governance_view":
      return <AddGovernanceViewForm isCloneMode />;
    case "mass_update":
      return <MassUpdateForm />;
    case "delete_terms":
      return <DeleteTerm />;
    case "delete_records":
      return <DeleteRecords />;
    case "delete_dictionary":
      return <DeleteDictionary />;
    case "discard_dictionary_confirmation":
      return <DiscardConfirmation />;

    case "connection_settings_dynamic_modal":
      return <ConnectionSettingsDynamicModal />;
    case "share_viewer":
      return <ShareViewerForm />;
    case "lineage_share_viewer":
      return <ShareViewerForm isLineageShare />;
    case "share_owner":
      return <ShareOwnerForm />;
    case "session_expired_modal":
      return <SessionExpiredModal />;
    case "delete_excel_source_file":
      return <DeleteExcelSourceFile />;
    case "add_tagset":
      return <AddTagsetForm />;
    case "merge_tags":
      return <MergeTagsForm />;
    case "delete_tags":
      return <DeleteTagsForm />;
    case "review_recomended_entity_modal":
      return <ReviewRecomendedEntityForm />;
    case "add_recomended_entity_modal":
      return <ReviewRecomendedEntityForm isAddMode />;
    case "listing_page_add_tags":
      return <UpdateTags />;
    case "listing_page_remove_tags":
      return <UpdateTags isRemove />;
    case "rule_type_detail_modal":
      return <DQRuleDetailsModal />;
    case "re_send_invite_to_user":
      return <ResendInviteUserModal />;
    case "quick_edit_for_col_confirmation":
      return <QuickEditForColConfirmationModal />;
    case "data_import_submitted_modal":
      return <DataImportSubmittedModal />;
    case "dvsum_help_drawer_detail_modal":
      return <DvsumHelpDrawerDetailModal />;
    case "ssa_save_analysis_modal":
      return <SSASaveAnalysisModal />;
    case "ssa_add_query_modal":
      return <SSAAddQueryModal />;
    case "edit_prompt":
      return <EditPromptModal />;
    case "subscription_deleted_modal":
      return <InformationModal />;
    case "train_bot_feedback_modal":
      return <TrainBotFeedbackModal />;
    case "train_bot_share_concept_modal":
      return <TrainbotShareConceptForm />;
    case "cancel_scan_modal":
      return <CancelScanModal />;
    case "my_preferences_modal":
      return <MyPreferencesModal />;
    case "review_caddi_feedbacks_modal":
      return <ReviewCaddiFeedbacksModal />;

    case "column_sequence_modal":
      return <ColumnSequenceModal />;
    case "update_schedule_modal":
      return <UpdateScheduleModal />;

    case "add_reference_dictionary":
      return <AddReferenceDictionary />;

    case "remove_schedule_modal":
      return <RemoveScheduleModal />;
    case "data_classification":
      return <DataClassification />;
    case "add_rule":
      return <AddRuleForm />;
    case "clone_rule_summary_modal":
      return <CloneRuleSummaryModal />;
    case "cancel_profiling_modal":
      return <CancelProfilingModal />;
    case "add_new_job_modal":
      return <AddNewJobForm />;

    case "clone_rule_modal":
      return <CloneRuleModal />;
    case "resolve_modal":
      return <ResolveModal />;
    case "cancel_schedule":
      return <CancelScheduleModal />;

    default:
      return <div />;
  }
}

export function getModalTitle(
  id: AppState["modal"]["modalId"],
  title: AppState["modal"]["modalTitle"]
): string | JSX.Element {
  switch (id) {
    case "add_user_group":
    case "edit_user_group":
    case "add_user":
    case "edit_user":
    case "add_sso":
    case "edit_sso":
    case "add_glossary_domain":
    case "edit_glossary_domain":
    case "add_glossary_category":
    case "edit_glossary_category":
    case "add_governance_view":
    case "edit_governance_view":
    case "clone_governance_view":
    case "add_rule":
      return <ModalFullScreenTitleStyled>{title}</ModalFullScreenTitleStyled>;
    case "delete_user_group":
    case "delete_user_role":
    case "add_source":
    case "add_source_fields":
    case "add_term":
    case "add_table":
    case "enable_source":
    case "delete_glossary_domain":
    case "delete_glossary_category":
    case "share_owner":
    case "add_tagset":
    case "merge_tags":
    case "delete_tags":
      return <MediumModalTitleStyled>{title}</MediumModalTitleStyled>;
    case "add_user_role":
    case "edit_user_role":
      return <AddUserRoleFormStyled>{title}</AddUserRoleFormStyled>;
    default:
      return title || "";
  }
}

export function getCrossIcon(id: AppState["modal"]["modalId"]): JSX.Element {
  switch (id) {
    case "create_list_form":
      return <CreateListForm />;
    case "add_user_group":
    case "edit_user_group":
    case "add_user_role":
    case "edit_user_role":
    case "add_user":
    case "edit_user":
    case "add_source":
    case "add_source_fields":
    case "add_term":
    case "add_table":
    case "add_sso":
    case "edit_sso":
    case "add_glossary_domain":
    case "edit_glossary_domain":
    case "add_glossary_category":
    case "edit_glossary_category":
    case "add_governance_view":
    case "edit_governance_view":
    case "clone_governance_view":
    case "share_owner":
    case "add_tagset":
    case "merge_tags":
    case "delete_tags":
    case "rule_type_detail_modal":
      return crossIcon("20px", "20px");
    default:
      return crossIcon("16px", "16px");
  }
}
