import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";

import { DataNode } from "antd/lib/tree";

import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import { useRequestWithMethod } from "../../api";
import { Button, TabsLayout } from "../../components";

import { API_CONFIG } from "../../constants/apiconfig";
import { UserRolesGridColumnsType } from "../../pages/userrolespage/userrolespage.types";

import {
  TranferStateType,
  TransferRecordType,
} from "../../components/transfer/transfer.types";

import { AddUserRoleFormStyled } from "./addroleform.styles";
import StateHandler from "../../components/statehandler/statehandler";

import {
  AddRoleFormContentProps,
  AddRoleFormTabsType,
  AddUserRoleFormProps,
  AddUserRoleFormType,
} from "./addroleform.types";

import {
  useGetAllAccessTypes,
  useGetAllUserGroupsForRoles,
} from "../../api/userservice";

import { addRoleSchema } from "../../utils/schemas/userroleschemas";
import { useCancelModal, useGetAppState, useSetData } from "../../customhooks";

import { GetAllUserGroupsParserReturnType } from "../../parsers/userroles/userrolesparser.types";
import { ADD_USER_ROLE_TABS_LIST } from "./addroleform.constants";

import { FormItemStyled, FormStyled } from "../../components/form";

import {
  AddRoleFormAdminTab,
  AddRoleFormCatalogTab,
  AddRoleFormGeneralTab,
  AddRoleFormGlossaryTab,
} from "./addroleform.views";

import {
  useGetGovernanceViewFields,
  useGetGovernanceViewFilters,
} from "../../api/listingsservice";

import {
  useGetEnabledObjects,
  useGetUserRoleInfo,
} from "../../api/userservice/userrolesservice";

import {
  useGetDomainsList,
  useGetSourcesList,
} from "../../api/treetransferservice";

import {
  getEnabledObjectsFromDestinationData,
  getSourceAndDestinationDataWithEnabledObjects,
} from "./addroleform.utils";

import {
  DomainDataNodeType,
  SourceDataNodeType,
} from "../../parsers/treetransferparser/treetransferparser.types";

import { formCriteriaToApiData } from "../addgovernanceviewform/addgovernanceviewform.utils";

const defaultCriteria = {
  column: undefined,
  filter: undefined,
  data_type: "",
  value: "",
  second_value: "",
  date_type: "REL",
  date_value: undefined,
  second_date_value: undefined,
  values_list: undefined,
};

const AddUserRoleFormContent = (
  props: AddRoleFormContentProps
): JSX.Element => {
  const {
    catalogAccessInfo,
    glossaryAccessInfo,
    parsedAccessTypes,
    isEditMode,
    catalogDestinationData,
    glossaryDestinationData,
    tableFieldsParsedData,
    termFieldsParsedData,
    state,
    selectedTab,
    onChangeTab,
    setScrollSecHeightDiff,
    sortedParsedUserGrous,
    catalogSourceData,
    catalogTreeData,
    glossarySourceData,
    glossaryTreeData,
    setCatalogSourceData,
    setCatalogDestinationData,
    setGlossaryDestinationData,
    setGlossarySourceData,
    parsedFilters,
    setState,
    isCatalogContentLoading,
    isGlossaryContentLoading,
    error,
    setGlossaryTreeData,
    setCatalogTreeData,
  } = props;
  const queryClient = useQueryClient();
  const onSetData = useSetData();

  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    id = "",
    name = "",
    description = "",
    groupIds = [],
    is_predefined_role: isPredefinedRole = false,
    admin_access_type_id: adminAccessTypeId = (parsedAccessTypes?.USERROLE_ADMIN
      ?.length &&
      parsedAccessTypes?.USERROLE_ADMIN[0]?.id) ||
      "NOA",
    catalog_access_type_id: catalogAccessTypeId = (parsedAccessTypes
      ?.USERROLE_CATALOG?.length &&
      parsedAccessTypes?.USERROLE_CATALOG[0]?.id) ||
      "VWR",
    glossary_access_type_id: glossaryAccessTypeId = (parsedAccessTypes
      ?.USERROLE_GLOSSARY?.length &&
      parsedAccessTypes?.USERROLE_GLOSSARY[0]?.id) ||
      "VWR",
  } = modalProps as UserRolesGridColumnsType;

  const {
    is_citeria_exists: isTableCriteriaEnabled,
    filter_criteria: tableFilterCriteria,
    is_finegrain_enabled: catalogIsFinegrainEnabled,
    default_flag: catalogDefaultFlag,
    is_edit_mode: catalogIsEditMode,
  } = catalogAccessInfo || {};

  const {
    is_citeria_exists: isTermCriteriaEnabled,
    filter_criteria: termFilterCriteria,
    is_finegrain_enabled: glossaryIsFinegrainEnabled,
    default_flag: glossaryDefaultFlag,
    is_edit_mode: glossaryIsEditMode,
  } = glossaryAccessInfo || {};

  const addRoleForm = useForm<AddUserRoleFormType>({
    defaultValues: {
      name: name || "",
      description: description || "",
      admin_access_type: adminAccessTypeId,
      catalog_access_type: catalogAccessTypeId,
      catalog_fine_grain_enabled: catalogIsFinegrainEnabled,
      catalog_table_criteria_enabled: isTableCriteriaEnabled,
      catalog_table_criteria: catalogIsEditMode ? [] : [defaultCriteria],
      glossary_access_type: glossaryAccessTypeId,
      glossary_fine_grain_enabled: glossaryIsFinegrainEnabled,
      glossary_term_criteria_enabled: isTermCriteriaEnabled,
      glossary_term_criteria: glossaryIsEditMode ? [] : [defaultCriteria],
    },
    resolver: yupResolver(addRoleSchema),
    mode: "onChange",
  });
  const {
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { isValid },
  } = addRoleForm;

  const roleName = watch("name");
  const catalogFineGrainEnabledWatched = watch("catalog_fine_grain_enabled");
  const glossaryFineGrainEnabledWatched = watch("glossary_fine_grain_enabled");

  const isSaveDisabled: boolean =
    !isValid ||
    (catalogFineGrainEnabledWatched && !catalogDestinationData?.length) ||
    (glossaryFineGrainEnabledWatched && !glossaryDestinationData?.length);

  const onCancel = useCancelModal();

  const onSuccess = useCallback(
    (response, finalObj) => {
      const api = API_CONFIG?.get_all_user_roles_for_role_page?.url;
      const responseRoleId = response?.data?.ROLE_ID || "";

      onSetData(API_CONFIG.get_user_role, response?.data, [
        isEditMode ? `${id}` : `${responseRoleId}`,
      ]);

      onSetData(
        API_CONFIG.get_user_role_enabled_objects,
        finalObj?.CTG?.enabled_objects,
        [isEditMode ? `${id}` : `${responseRoleId}`, "CTG"]
      );

      onSetData(
        API_CONFIG.get_user_role_enabled_objects,
        finalObj?.GLS?.enabled_objects,
        [isEditMode ? `${id}` : `${responseRoleId}`, "GLS"]
      );

      queryClient.invalidateQueries(api, { fetching: false });
      onCancel();
    },
    [modalProps]
  );

  const {
    isLoading: isSavingRoleForm,
    error: errorInRoleForm,
    onExecuteRequest,
  } = useRequestWithMethod(
    isEditMode ? "edit_user_role" : "add_user_role",
    isEditMode ? [id] : undefined,
    true
  );

  const onSubmit = useCallback(
    (values) => {
      const isCatalogEditor =
        values?.catalog_access_type === "EDT" ||
        values?.catalog_access_type === "VWR";
      const isGlossaryEditor =
        values?.glossary_access_type === "EDT" ||
        values?.glossary_access_type === "VWR";

      const isCatalogFineGrainEnabled =
        values?.catalog_fine_grain_enabled && isCatalogEditor;

      const isCalatlogExclCriteriaEnabled =
        values?.catalog_table_criteria_enabled && isCatalogEditor;

      const isGlossaryFineGrainEnabled =
        values?.glossary_fine_grain_enabled && isGlossaryEditor;

      const isGlossaryExclCriteriaEnabled =
        values?.glossary_term_criteria_enabled && isGlossaryEditor;

      const finalObj = {
        name: values?.name,
        description,
        user_group_ids: state?.targetKeys?.join(",") || "",
        admin_access_type: values?.admin_access_type || "",
        catalog_access_type: values?.catalog_access_type || "",
        glossary_access_type: values?.glossary_access_type || "",
        CTG: {
          default_flag: catalogDefaultFlag || false,
          is_finegrain_enabled: isCatalogFineGrainEnabled || false,
          enabled_objects: isCatalogFineGrainEnabled
            ? getEnabledObjectsFromDestinationData(
                "SRC",
                (catalogDestinationData || []) as SourceDataNodeType[],
                catalogTreeData
              )
            : [],
          filter_criteria: {
            TBL: isCalatlogExclCriteriaEnabled
              ? {
                  filter: formCriteriaToApiData(
                    values?.catalog_table_criteria,
                    tableFieldsParsedData
                  ),
                }
              : undefined,
          },
        },
        GLS: {
          default_flag: glossaryDefaultFlag || false,
          is_finegrain_enabled: isGlossaryFineGrainEnabled || false,
          enabled_objects: isGlossaryFineGrainEnabled
            ? getEnabledObjectsFromDestinationData(
                "DMN",
                (glossaryDestinationData || []) as DomainDataNodeType[],
                glossaryTreeData
              )
            : [],
          filter_criteria: {
            TRM: isGlossaryExclCriteriaEnabled
              ? {
                  filter: formCriteriaToApiData(
                    values?.glossary_term_criteria,
                    termFieldsParsedData
                  ),
                }
              : undefined,
          },
        },
      };
      onExecuteRequest({ ...finalObj }, undefined, (apiRes) =>
        onSuccess(apiRes, finalObj)
      );
    },
    [
      modalProps,
      state,
      catalogDestinationData,
      glossaryDestinationData,
      tableFieldsParsedData,
      termFieldsParsedData,
      catalogDefaultFlag,
      glossaryDefaultFlag,
    ]
  );

  useEffect(() => {
    const alertDom = document.querySelector(".ant-modal-body .ant-alert-error");

    if (alertDom) {
      setScrollSecHeightDiff(
        (st) => st + alertDom?.getBoundingClientRect().height
      );
    }
  }, [errorInRoleForm]);

  const onResetTables = useCallback(
    (resetCheckbox?: boolean) => {
      const currentValues = getValues();

      reset(
        {
          ...currentValues,
          catalog_table_criteria_enabled: resetCheckbox
            ? false
            : currentValues?.catalog_table_criteria_enabled,
          catalog_table_criteria: [defaultCriteria],
        },
        { keepDirty: true }
      );
    },
    [getValues]
  );

  const onResetTerms = useCallback(
    (resetCheckbox?: boolean) => {
      const currentValues = getValues();

      reset(
        {
          ...currentValues,
          glossary_term_criteria_enabled: resetCheckbox
            ? false
            : currentValues?.glossary_term_criteria_enabled,
          glossary_term_criteria: [defaultCriteria],
        },
        { keepDirty: true }
      );
    },
    [getValues]
  );

  const transferUserGroups = useMemo(() => {
    return sortedParsedUserGrous.map((item: TransferRecordType) => ({
      ...item,
      disabled: item?.disabled,
      description: state.targetKeys?.includes(item?.key)
        ? roleName
        : groupIds?.includes(item?.key) &&
          !state.targetKeys?.includes(item?.key)
        ? "Groups"
        : item?.description,
    }));
  }, [roleName, sortedParsedUserGrous, state.targetKeys]);

  const renderPanel = (): JSX.Element => {
    const tabProps = {
      type: selectedTab,
      isPredefinedRole,
      parsedAccessTypes,
      transferState: state,
      transferSetState: setState,
      transferUserGroups,
    };

    switch (selectedTab) {
      case "general":
        return <AddRoleFormGeneralTab {...tabProps} />;
      case "admin":
        return <AddRoleFormAdminTab {...tabProps} />;
      case "system-catalog":
        return (
          <StateHandler
            isFetching={isCatalogContentLoading}
            error={error}
            isModal
          >
            <AddRoleFormCatalogTab
              {...tabProps}
              levelOfAccessOptions={[
                { label: "View & Chat", value: "VIW" },
                { label: "Edit", value: "EDT" },
              ]}
              treeData={catalogTreeData}
              sourceData={catalogSourceData}
              destinationData={catalogDestinationData}
              updateTreeData={(data: DataNode[]): void =>
                setCatalogTreeData(data)
              }
              updateSourceData={(data: DataNode[]): void =>
                setCatalogSourceData(data)
              }
              updateDestinationData={(data: DataNode[]): void =>
                setCatalogDestinationData(data)
              }
              criteriaParsedFields={tableFieldsParsedData}
              parsedFilters={parsedFilters}
              onResetCriteria={onResetTables}
              isEditMode={catalogIsEditMode}
              criteriaFields={tableFilterCriteria}
            />
          </StateHandler>
        );
      case "business-glossary":
        return (
          <StateHandler
            isFetching={isGlossaryContentLoading}
            error={error}
            isModal
          >
            <AddRoleFormGlossaryTab
              {...tabProps}
              levelOfAccessOptions={[
                { label: "View", value: "VIW" },
                { label: "Edit", value: "EDT" },
              ]}
              treeData={glossaryTreeData}
              sourceData={glossarySourceData}
              destinationData={glossaryDestinationData}
              updateTreeData={(data: DataNode[]): void =>
                setGlossaryTreeData(data)
              }
              updateSourceData={(data: DataNode[]): void =>
                setGlossarySourceData(data)
              }
              updateDestinationData={(data: DataNode[]): void =>
                setGlossaryDestinationData(data)
              }
              criteriaParsedFields={termFieldsParsedData}
              parsedFilters={parsedFilters}
              onResetCriteria={onResetTerms}
              isEditMode={glossaryIsEditMode}
              criteriaFields={termFilterCriteria}
            />
          </StateHandler>
        );
      default:
        return <div />;
    }
  };

  return (
    <StateHandler isFetching={isSavingRoleForm} error={errorInRoleForm} isModal>
      <FormProvider {...addRoleForm}>
        <FormStyled onFinish={handleSubmit(onSubmit) as any} isItemColumnLayout>
          <TabsLayout
            tabsList={ADD_USER_ROLE_TABS_LIST}
            selectedTab={selectedTab}
            onChangeTab={onChangeTab}
            renderPanel={renderPanel}
          />

          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button id="cancel" width="74px" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="74px"
              marginLeft="8px"
              htmlType="submit"
              disabled={isSaveDisabled}
              tooltipProps={{
                title: isSaveDisabled ? "Please fill the required fields." : "",
                placement: "topLeft",
              }}
            >
              Save
            </Button>
          </FormItemStyled>
        </FormStyled>
      </FormProvider>
    </StateHandler>
  );
};

const AddUserRoleForm = (props: AddUserRoleFormProps): JSX.Element => {
  const { isEditMode } = props;

  const [scrollSecHeightDiff, setScrollSecHeightDiff] = useState<number>(142);

  const [selectedTab, setSelectedTab] = useState<string | AddRoleFormTabsType>(
    ADD_USER_ROLE_TABS_LIST?.[0]?.key as AddRoleFormTabsType
  );

  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    parsedData: parsedUserGroups = [],
    isLoading: isLoadingUserGroup,
    error: errorGettingUserGroups,
  } = useGetAllUserGroupsForRoles();

  const {
    parsedData: parsedAccessTypes = [],
    isLoading: isFetchingAccessTypes,
    error: errorFetchingAccessTypes,
  } = useGetAllAccessTypes();

  const sortedParsedUserGrous = useMemo(() => {
    return parsedUserGroups.sort(
      (
        a: GetAllUserGroupsParserReturnType,
        b: GetAllUserGroupsParserReturnType
      ) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)
    );
  }, [parsedUserGroups]);

  const { id = "", groupIds = [] } = modalProps as UserRolesGridColumnsType;

  const [state, setState] = useState<TranferStateType>({
    data: sortedParsedUserGrous,
    targetKeys: isEditMode ? groupIds : [],
  });

  const {
    isLoading: sourcesListIsLoading,
    error: sourcesListError,
    parsedData: sourcesListParsedData = [],
  } = useGetSourcesList(true, "");

  const {
    parsedData: catalogEnabledObjectsParsedData = [],
    isLoading: catalogEnabledObjectsIsLoading,
  } = useGetEnabledObjects(`${id}`, "CTG");

  const [catalogTreeData, setCatalogTreeData] = useState<DataNode[]>(
    sourcesListParsedData
  );

  const [catalogSourceData, setCatalogSourceData] = useState<DataNode[]>(
    sourcesListParsedData
  );

  const [catalogDestinationData, setCatalogDestinationData] = useState<
    DataNode[]
  >([]);

  const {
    isLoading: domainsListIsLoading,
    error: domainsListError,
    parsedData: domainsListParsedData = [],
  } = useGetDomainsList(true);

  const {
    parsedData: glossaryEnabledObjectsParsedData = [],
    isLoading: glossaryEnabledObjectsIsLoading,
  } = useGetEnabledObjects(`${id}`, "GLS");

  const [glossaryTreeData, setGlossaryTreeData] = useState<DataNode[]>(
    domainsListParsedData
  );

  const [glossarySourceData, setGlossarySourceData] = useState<DataNode[]>(
    domainsListParsedData
  );
  const [glossaryDestinationData, setGlossaryDestinationData] = useState<
    DataNode[]
  >([]);

  const { parsedData: parsedFilters } = useGetGovernanceViewFilters(
    "role_access"
  );

  const {
    parsedData: termFieldsParsedData,
    isLoading: termFieldsIsLoading,
  } = useGetGovernanceViewFields("TRM", "role_access", true);

  const {
    parsedData: tableFieldsParsedData,
    isLoading: tableFieldsIsLoading,
  } = useGetGovernanceViewFields("TBL", "role_access", true);

  const {
    parsedData: userRoleInfoParsedData,
    isLoading: userRoleInfoIsLoading,
    error: userRoleInfoError,
  } = useGetUserRoleInfo(`${id}`);

  useEffect(() => {
    if (!sourcesListIsLoading && !catalogEnabledObjectsIsLoading) {
      setCatalogTreeData(sourcesListParsedData);

      if (catalogEnabledObjectsParsedData?.length) {
        const {
          treeData: catalogTreeDataEnabled,
          sourceData: catalogSourceDataEnabled,
          destinationData: catalogDestinationDataEnabled,
        } = getSourceAndDestinationDataWithEnabledObjects(
          catalogEnabledObjectsParsedData,
          sourcesListParsedData
        );

        setCatalogTreeData(catalogTreeDataEnabled);
        setCatalogSourceData(catalogSourceDataEnabled);

        setCatalogDestinationData(catalogDestinationDataEnabled);
      } else {
        setCatalogSourceData(sourcesListParsedData);
      }
    }
  }, [sourcesListIsLoading, catalogEnabledObjectsIsLoading]);

  useEffect(() => {
    if (!domainsListIsLoading && !glossaryEnabledObjectsIsLoading) {
      setGlossaryTreeData(domainsListParsedData);

      if (glossaryEnabledObjectsParsedData?.length) {
        const {
          treeData: glossaryTreeDataEnabled,
          sourceData: glossarySourceDataEnabled,
          destinationData: glossaryDestinationDataEnabled,
        } = getSourceAndDestinationDataWithEnabledObjects(
          glossaryEnabledObjectsParsedData,
          domainsListParsedData
        );

        setGlossaryTreeData(glossaryTreeDataEnabled);
        setGlossarySourceData(glossarySourceDataEnabled);
        setGlossaryDestinationData(glossaryDestinationDataEnabled);
      } else {
        setGlossarySourceData(domainsListParsedData);
      }
    }
  }, [domainsListIsLoading, glossaryEnabledObjectsIsLoading]);

  const onChangeTab = useCallback((newTab: string | AddRoleFormTabsType) => {
    setSelectedTab(newTab);
  }, []);

  return (
    <StateHandler
      showSpinner={
        isLoadingUserGroup ||
        isFetchingAccessTypes ||
        sourcesListIsLoading ||
        catalogEnabledObjectsIsLoading ||
        glossaryEnabledObjectsIsLoading ||
        tableFieldsIsLoading ||
        termFieldsIsLoading ||
        userRoleInfoIsLoading ||
        domainsListIsLoading ||
        sourcesListIsLoading
      }
      error={errorGettingUserGroups || errorFetchingAccessTypes}
    >
      <AddUserRoleFormStyled
        maxHeight={`calc(100vh - ${scrollSecHeightDiff}px)`}
      >
        <AddUserRoleFormContent
          catalogAccessInfo={userRoleInfoParsedData?.catalog_access_info}
          glossaryAccessInfo={userRoleInfoParsedData?.glossary_access_info}
          parsedAccessTypes={parsedAccessTypes}
          isEditMode={isEditMode}
          catalogDestinationData={catalogDestinationData}
          glossaryDestinationData={glossaryDestinationData}
          tableFieldsParsedData={tableFieldsParsedData}
          termFieldsParsedData={termFieldsParsedData}
          state={state}
          selectedTab={selectedTab}
          onChangeTab={onChangeTab}
          setScrollSecHeightDiff={setScrollSecHeightDiff}
          sortedParsedUserGrous={sortedParsedUserGrous}
          catalogTreeData={catalogTreeData}
          catalogSourceData={catalogSourceData}
          glossaryTreeData={glossaryTreeData}
          glossarySourceData={glossarySourceData}
          setCatalogSourceData={setCatalogSourceData}
          setCatalogDestinationData={setCatalogDestinationData}
          setGlossarySourceData={setGlossarySourceData}
          setGlossaryDestinationData={setGlossaryDestinationData}
          parsedFilters={parsedFilters}
          setState={setState}
          isGlossaryContentLoading={domainsListIsLoading}
          isCatalogContentLoading={sourcesListIsLoading}
          error={userRoleInfoError || domainsListError || sourcesListError}
          setGlossaryTreeData={setGlossaryTreeData}
          setCatalogTreeData={setCatalogTreeData}
        />
      </AddUserRoleFormStyled>
    </StateHandler>
  );
};

export default memo(AddUserRoleForm);
