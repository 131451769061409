//          <--Library Imports-->
import { BarChartProps, BarChart as Chart } from "@k2/rv-viz";
import { useMemo } from "react";
//          <--Library Imports-->

//          <--Styles Start-->
import { BarchartStyled } from "./barchart.styles";
//          <--Styles End-->

//          <--Types Start-->
import { BarchartStyledProps } from "./barchart.types";
//          <--Types End-->

//          <--Utils Start-->
import { calculateElementSize, numberFormatter } from "../../../utils";
//          <--Utils End-->

function BarChart(props: BarChartProps & BarchartStyledProps): JSX.Element {
  const { data: chartData = [], xyPlot = {} } = props;

  const combineData = useMemo(() => {
    const allData = chartData?.map((item) => item?.data || [])?.flat() || [];
    return allData;
  }, [chartData]);

  const combineYAxisData = useMemo(() => {
    const yAxisValues = combineData?.map((item) => item?.y || 0);
    return yAxisValues;
  }, [combineData]);

  const maxXAxisDomainVal = useMemo(() => {
    const xAxisValues = combineData?.map((item) => Number(item?.x || 0));
    const maxVal = Math.max(...xAxisValues);
    const oneFourOfMaxVal = maxVal / 4;
    const updatedMaxXAxisVal = maxVal + oneFourOfMaxVal;
    return updatedMaxXAxisVal;
  }, [combineData]);

  const maxYAxisValueWidth = useMemo(
    () =>
      Math.max(
        ...(combineYAxisData?.map(
          (item) =>
            calculateElementSize(`${item}`, {
              fontSize: "14px",
              fontFamily: "openSansSemiBold",
            })?.clientWidth || 0
        ) || [])
      ),
    [combineYAxisData]
  );

  return (
    <BarchartStyled
      width={props?.width}
      height={props?.height}
      className="dvsum-bar-chart"
    >
      <Chart
        barWidth={0.4}
        classes={{
          barSeries: "barseries-bar",
          chart: "chart-bar",
          header: "header-bar",
          label: "label-bar",
          legends: "legends-bar",
          root: "root-bar",
          title: "title-bar",
          tooltip: "tooltip-bar",
          xAxis: "xAxis-bar",
          yAxis: "yAxis-bar",
        }}
        xAxis={{
          tickTotal: 5,
        }}
        yAxis={{
          hideLine: true,
          tickPadding: 0,
          tickSize: 12,
        }}
        legends={false}
        horizontalGridLines={false}
        verticalGridLines={{
          style: {
            strokeWidth: 0.5,
          },
        }}
        label={(props): JSX.Element => {
          return <span>{numberFormatter(`${props?.data?.x}`)}</span>;
        }}
        {...props}
        xyPlot={{
          ...xyPlot,
          margin:
            typeof xyPlot?.margin === "object"
              ? {
                  left: maxYAxisValueWidth + 20,
                  ...xyPlot?.margin,
                }
              : typeof xyPlot?.margin === "number"
              ? xyPlot?.margin
              : {
                  left: maxYAxisValueWidth + 20,
                },
          //@ts-ignore
          xDomain: [0, maxXAxisDomainVal],
        }}
      />
    </BarchartStyled>
  );
}

export default BarChart;
