import styled from "styled-components";

export const PieChartStyled = styled.div`
  .recharts-legend-wrapper {
    width: fit-content !important;

    .legends-wrapper {
      flex-direction: column;
    }
  }
`;
