import { Tooltip } from "antd";
import { useMemo, useCallback, useRef, useState } from "react";

import { useController } from "react-hook-form";

import { REQUIRED_MESSAGE } from "../../../constants/formconstants";
import { chevronDown1 } from "../../../svgs";

import { SelectContainerStyled, SelectFieldStyled } from "./selectfield.styles";
import { SelectieldType } from "./selectfield.types";

import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../constants";

function SelectField({
  control,
  name,
  options = [],
  setValue,
  setError,
  height = "34px",
  maxHeight,
  isMessagePositionAbsolute = false,
  isAllowClear = true,
  allowClearLabel = "Select",
  propOnChange,
  className = "",
  isUseGlobalStyle = false,
  isGroupedOpt = false,
  shouldAddDescInLabel = false,
  tooltipPlacement = "left",
  tooltipClassName = DVSUM_TOOLTIP_CLASS_NAME,
  ...props
}: SelectieldType): JSX.Element {
  const { allowClear, onClear, mode } = props;

  const isMultipleMode = mode === "multiple";

  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({ name, control });

  const [searchTextForSelect, setSearchTextForSelect] = useState("");

  const selectRef: any = useRef(ref);

  const handleChange = useCallback(
    (option, selectOption) => {
      if (
        option === "select" ||
        (allowClear && option === undefined && !onClear)
      ) {
        setValue(name, undefined, { shouldValidate: true, shouldDirty: true });
        setError && setError(name, { message: REQUIRED_MESSAGE });
        propOnChange && propOnChange(option, selectOption);
      } else {
        inputProps?.onChange(option);
        propOnChange && propOnChange(option, selectOption);
      }
    },
    [name, inputProps, allowClear, isMultipleMode, propOnChange]
  );

  const updatedOptions = useMemo(() => {
    if (isGroupedOpt) {
      const filteredOpts = options?.map((opt) => ({
        ...opt,
        options: opt?.options?.filter(
          (item: SelectieldType["options"][number]) =>
            (item?.labelText || "")
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchTextForSelect?.toString()?.toLowerCase())
        ),
      }));

      const filteredOptsWhereOptsExists = filteredOpts?.filter(
        (opt) => opt?.options?.length
      );

      const optionsWithLabelDesc = shouldAddDescInLabel
        ? filteredOptsWhereOptsExists?.map((opt) => ({
            ...opt,
            options: opt?.options?.map(
              (nestedOpts: SelectieldType["options"][number]) => ({
                ...nestedOpts,
                label: (
                  <Tooltip
                    title={nestedOpts?.labelDesc}
                    overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                    placement={tooltipPlacement}
                    // getPopupContainer={(trigger): HTMLElement =>
                    //   trigger.parentNode as HTMLElement
                    // }
                  >
                    {nestedOpts?.label}
                  </Tooltip>
                ),
              })
            ),
          }))
        : filteredOptsWhereOptsExists;

      return optionsWithLabelDesc[0]?.label
        ? optionsWithLabelDesc
        : optionsWithLabelDesc[0]?.options;
    }

    const finalOptions =
      !isMultipleMode && isAllowClear
        ? [{ label: allowClearLabel, value: "select" }, ...options]
        : options;

    const optionsWithLabelDesc = shouldAddDescInLabel
      ? finalOptions?.map((opt) => ({
          ...opt,
          label: (
            <Tooltip
              title={opt?.labelDesc}
              overlayClassName={tooltipClassName}
              // we can make it configurable through props
              placement={tooltipPlacement}
            >
              {opt?.label}
            </Tooltip>
          ),
        }))
      : finalOptions;

    return optionsWithLabelDesc;
  }, [
    options,
    isAllowClear,
    allowClearLabel,
    searchTextForSelect,
    shouldAddDescInLabel,
  ]);

  const onChange = useCallback(
    (input) => {
      setSearchTextForSelect(input || "");
    },
    [searchTextForSelect]
  );

  const onBlur = useCallback(() => {
    setSearchTextForSelect("");
  }, [searchTextForSelect]);

  return (
    <SelectContainerStyled
      isMessagePositionAbsolute={isMessagePositionAbsolute}
      className="select-field-container"
    >
      <SelectFieldStyled
        notFoundContent={
          <div className="no-result-fount-content">No results match </div>
        }
        virtual
        {...props}
        {...inputProps}
        onChange={handleChange}
        options={updatedOptions}
        className={`dvsum-select-field ${className}`}
        getPopupContainer={
          !isUseGlobalStyle
            ? (trigger): HTMLElement => trigger.parentNode as HTMLElement
            : undefined
        }
        dropdownClassName="multiselect-dropdown-dvsum"
        ref={selectRef}
        onSelect={(): void => mode !== "multiple" && selectRef?.current.blur()}
        multipleMode={mode === "multiple"}
        height={height}
        maxHeight={maxHeight}
        {...(!props?.loading && {
          suffixIcon: chevronDown1("11.7px", "6.9px"),
        })}
        value={inputProps?.value === "" ? undefined : inputProps?.value}
        {...(isGroupedOpt && {
          onSearch: onChange,
          onBlur,
          onFocus: onBlur,
        })}
      />
      <div className="error">{error?.message}</div>
    </SelectContainerStyled>
  );
}

export default SelectField;
