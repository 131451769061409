// <--- Types Start --->
import { SourceTypes } from "../app.types";
import { salesForceLogoSvg } from "../svgs";
// <--- Types End --->

const salesForceLogo = salesForceLogoSvg();

export function getDataSourceLogoIconByType(
  sourceType: SourceTypes
): JSX.Element {
  switch (sourceType) {
    case "SNF":
      return (
        <img
          src="/assets/icons/snowflake/snowflake.png"
          alt="app-logo"
          title="Snowflake"
        />
      );
    case "SLF":
      return salesForceLogo;
    case "MSS":
      return (
        <img
          src="/assets/icons/sqlserver/sql-server.png"
          alt="app-logo"
          title="Sql Server"
        />
      );
    case "ORC":
      return (
        <img
          src="/assets/icons/oracle/oracle.png"
          alt="app-logo"
          title="Oracle"
        />
      );
    case "MSQ":
      return (
        <img
          src="/assets/icons/mysql/mysql.png"
          alt="app-logo"
          title="My SQL"
        />
      );
    case "DB2":
      return (
        <img src="/assets/icons/ibmdb/ibm-db2.png" alt="app-logo" title="DB2" />
      );
    case "EXL":
      return (
        <img src="/assets\icons\excel\excel-mini.png" title="File Upload" />
      );
    case "AS3":
      return <img src="/assets\icons\s3\s3.png" title="S3" />;
    case "KFK":
      return <img src="/assets\icons\kafka\kafka.png" title="Kafka" />;
    case "ASQ":
      return (
        <img src="/assets\icons\azuresql\azuresql.png" title="Azure SQL" />
      );
    case "SNP":
      return <img src="/assets\icons\synapse\synapse.png" title="Synapse" />;
    case "TBU":
      return (
        <img
          src="/assets\icons\tableau\tableausoftwareicon.png"
          style={{
            width: 19,
            height: 19,
          }}
          title="Tableau"
        />
      );
    case "PBI":
      return <img src="/assets\icons\power-bi\power-bi.png" title="Power BI" />;
    case "ADL":
      return (
        <img
          src="/assets\icons\azure-data-lake\azure-data-lake.png"
          title="Azure Data Lake"
        />
      );
    case "DBS":
      return (
        <img
          src="/assets\icons\data-bricks\data-bricks.png"
          style={{
            width: 19,
            height: 19,
          }}
          title="Databricks"
        />
      );
    case "PSQ":
      return (
        <img
          src="/assets\icons\postgresql\postgresql-icon.png"
          style={{
            width: 19,
            height: 19,
          }}
          title="Postgress SQL"
        />
      );
    case "MDB":
      return (
        <img
          src="/assets/icons/mongodb/mongodb.png"
          style={{
            width: 47,
          }}
          title="MongoDb"
        />
      );
    case "RST":
      return (
        <img
          src="/assets/icons/restapisource/restapisource.png"
          alt="app-logo"
          title="Rest Data Source"
        />
      );
    default:
      return <img />;
  }
}
