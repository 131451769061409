import styled from "styled-components";
import { SelectStyled } from "../../selectdropdowns/selectdropdown/selectdropdown.styles";

export const SelectContainerStyled = styled.div<{
  isMessagePositionAbsolute?: boolean;
}>`
  .icon {
    margin: 0 0.4rem;
    font-size: 12px;
    font-weight: normal;
    color: #c3c3c3;
  }
  .error {
    text-align: right;
    color: red;

    ${(props): any =>
      props.isMessagePositionAbsolute &&
      `
    position:absolute;
    right:0;
    `}
  }
`;

export const SelectFieldStyled = styled(SelectStyled)``;
