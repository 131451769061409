import { format } from "date-fns";

//          <--BlankSlate Start-->
import { datQualityHistoryChartBlankslate } from "../../../../../../blankslates";
//          <--BlankSlate End-->

//          <--Components Start-->
import Boldtext from "../../../../../boldtext";

import StateHandler from "../../../../../statehandler";
import { LineChart } from "../../../../../charts";
//          <--Components Start-->

//          <--Service Start-->
import { useGetDataQualityHistoryChartData } from "../../../../../../api/dataqualityservice/dataqualityservice";
//          <--Service End-->

//          <Styles Start->
import { DataQualityHistoryChartStyled } from "./dataqualityhistorychart.styles";
//          <Styles End->

//          <--Types Start-->
import { DataQualityHistoryChartProps } from "./dataqualityhistorychart.types";
//          <--Types End-->

function DataQualityHistoryChart({
  width = "100%",
  height = "500px",
  title = "",
  customClass = "",
  nodeId = "",
  nodeType,
  ruleCategoryId,
}: DataQualityHistoryChartProps): JSX.Element {
  const { isLoading, parsedData, error } = useGetDataQualityHistoryChartData(
    nodeId,
    nodeType,
    ruleCategoryId
  );

  return (
    <DataQualityHistoryChartStyled
      className={customClass}
      data-testid={`data-quality-history-chart-component-${nodeId}`}
    >
      <StateHandler
        isFetching={isLoading}
        error={error}
        blankSlate={datQualityHistoryChartBlankslate}
      >
        <LineChart
          data-testid={`data-quality-line-chart-${nodeId}`}
          data={parsedData}
          crosshair={{ yFormatter: (val): string => `${val}%` }}
          xAxis={{
            title: "DQ Score",
            style: {
              fill: "#6c7780",
              fontSize: 13,
            },
            tickTotal: 8,
            tickFormat: (val): string => format(new Date(val || 0), "P"),
          }}
          yAxis={{
            tickFormat: (v): string => `${v}%`,
            style: {
              fill: "#959596",
              fontSize: 13,
            },
          }}
          xyPlot={{
            margin: { left: 50 },
            //@ts-ignore
            yDomain: [0, 100],
          }}
          verticalGridLines={false}
          horizontalGridLines
          width={width}
          height={height}
          title={
            title && (
              <Boldtext
                text={title}
                fontSize="16px"
                type="secondary"
                data-testid={`data-quality-line-chart-bold-title-text-${nodeId}`}
              />
            )
          }
          legends={false}
        />
      </StateHandler>
    </DataQualityHistoryChartStyled>
  );
}

export default DataQualityHistoryChart;
