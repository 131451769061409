import { Card } from "antd";

import {
  BarChart as Chart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";

import RechartHOC from "../recharthoc/recharthoc";
import { BarChartStyled } from "./barchart.styles";

import { RechartHOCEmbedProps } from "../recharthoc/recharthoc.types";
import { BarChartProps } from "./barchart.types";

import { numberFormatter } from "../../../utils";

import { DEFAULT_MARGIN } from "../defaultmargin";
import { DEFAULT_X_AXIS_LABEL } from "../defaultxaxislabel";

import { DEFAULT_Y_AXIS_LABEL } from "../defaultyaxislabel";
import Customizedaxistick from "../customizedaxistick";

const BarChartContent = (
  props: BarChartProps & RechartHOCEmbedProps
): JSX.Element => {
  const {
    width = 500,
    height = 500,
    data = [],
    clonedDataKeys = [],
    xAxislabel,
    legends,
    margin,
    isShowLegend,
    updatedYAxis,
    isMultipleYAxis,
    hideXAxis = false,
    showCartesianGrid = true,
    hideYAxis = false,
    customLabel = false,
  } = props;

  return (
    <Card>
      <BarChartStyled>
        <ResponsiveContainer width={width} height={height}>
          <Chart
            data={data}
            margin={{ ...DEFAULT_MARGIN, left: 75, ...margin }}
            layout="vertical"
          >
            {showCartesianGrid && (
              <CartesianGrid
                strokeDasharray="1"
                horizontal={false}
                stroke="#e6e6e6"
              />
            )}
            {isShowLegend && legends}
            <XAxis
              stroke="#8a8c8c"
              axisLine={{ stroke: "#e6e6e6" }}
              tickLine={false}
              type="number"
              tickFormatter={(val): string => {
                return numberFormatter(val);
              }}
              domain={([min, max]): [number, number] => {
                const updatedMin = min < 0 ? min : 0;
                return [updatedMin, max + max / 4];
              }}
              hide={hideXAxis}
            >
              {xAxislabel && (
                <Label {...{ ...DEFAULT_X_AXIS_LABEL, ...xAxislabel }} />
              )}
            </XAxis>

            {updatedYAxis?.map((item) => (
              <YAxis
                key={`bar-chart-y-axis-${item?.yAxisId}`}
                dataKey={item?.yAxisId}
                axisLine={{ stroke: "#e6e6e6" }}
                tickLine={false}
                stroke="#8a8c8c"
                type="category"
                {...(isMultipleYAxis && {
                  ...item,
                  label: undefined,
                })}
                hide={hideYAxis}
                tickFormatter={(val): string => {
                  return `${item?.prefix || ""}${
                    item?.notApplyFormatting ? val : numberFormatter(val)
                  }`;
                }}
                tick={<Customizedaxistick />}
              >
                {item?.label && (
                  <Label
                    {...{
                      ...DEFAULT_Y_AXIS_LABEL,
                      ...item?.label,
                    }}
                  />
                )}
              </YAxis>
            ))}

            <Tooltip
              formatter={(val): string => {
                return numberFormatter(`${val}`);
              }}
            />

            {clonedDataKeys?.map((key) => (
              <Bar
                {...key}
                barSize={32.8}
                key={`columnchart-datakey-${key?.dataKey}`}
                minPointSize={2}
                {...(isMultipleYAxis && { yAxisId: key?.dataKey?.toString() })}
                {...(customLabel && {
                  label: {
                    position: "right",
                    fill: "#000000",
                    fontFamily: "OpenSansBold",
                    formatter: numberFormatter,
                  },
                })}
              />
            ))}
          </Chart>
        </ResponsiveContainer>
      </BarChartStyled>
    </Card>
  );
};

const BarChart = (props: BarChartProps): JSX.Element => {
  return (
    <RechartHOC
      dataKeys={props?.dataKeys}
      yAxis={props?.yAxis}
      showLedgends={props?.showLedgends}
    >
      <BarChartContent {...props} />
    </RechartHOC>
  );
};
export default BarChart;
