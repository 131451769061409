import * as yup from "yup";
import { FilterCriteriaSchema } from "../filtercriteriaschema";
import { descriptionSchema } from "./ruledescriptionschema";

export const rulesetFormFilterCriteriaSchema = yup.object().shape({
  filter_criteria: yup.lazy((_p, p1) => {
    return yup.array().of(
      yup.object().shape({
        condition: yup.array().of(FilterCriteriaSchema),
      })
    );
  }),
});

export const rulesetFormSchema = yup.object().shape({
  rule_description: descriptionSchema,

  is_filter_criteria_active: yup.boolean(),

  filter_criteria: yup.lazy((_p, p1) => {
    if (p1?.parent?.is_filter_criteria_active) {
      return yup.array().of(
        yup.object().shape({
          condition: yup.array().of(FilterCriteriaSchema),
        })
      );
    }
    return yup.mixed().nullable();
  }),
});
