import styled from "styled-components";

export const TileViewStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`;

export const BoxStyled = styled.div`
  width: 327px;
  height: 231px;
  border: solid 1px ${({ theme }): string => theme?.bgColors?.GREY_56};
  background-color: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .count-text {
    font-family: "OpenSansBold";
    font-size: 28px;
    color: ${({ theme }): string => theme?.textColors?.GREY_40};
    margin: 0;
  }
  .caption-text {
    font-size: 16px;
    color: ${({ theme }): string => theme?.textColors?.GREY_24};
    margin: 0;
  }
`;
