import styled from "styled-components";
import { hexToRgbA } from "../../../utils";

export const BarchartStyled = styled.div<{ width?: string; height?: string }>`
  width: ${({ width }): string => width || "100%"};
  height: ${({ height }): string => height || "100%"};
  background: ${({ theme }): string =>
    hexToRgbA(theme.bgColors.LIGHT_BLUE, 0.5)};
  padding: 25px 20px 25px 0px;
  .yAxis-bar,
  .xAxis-bar {
    .rv-xy-plot__axis__ticks {
      .rv-xy-plot__axis__tick__text {
        fill: #777777;
        font-family: OpenSansBold;
      }
    }
  }

  .xAxis-bar {
    .rv-xy-plot__axis__ticks {
      .rv-xy-plot__axis__tick__text {
        font-family: OpenSans;
      }
    }
  }

  .rv-xy-plot__series--custom-svg {
    div {
      color: #000000;
      font-family: OpenSansBold;
    }
  }
`;
