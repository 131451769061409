import styled from "styled-components";
import LinkButton from "../../../../../../components/linkbutton/linkbutton";

export const AddRulesetFormGridStyled = styled.div`
  padding: 14px;

  .grid-search {
    background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
    padding: 20px 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .ant-input-affix-wrapper {
      width: 270px;
      height: 40px;
    }
  }
`;

export const AddRulesetFormGridWrapperStyled = styled.div`
  /* margin-left: 287px; */
  margin-top: 30px;
  width: 78%;

  border: 1px solid ${({ theme }): string => theme.borderColors.GREY_110};
  background: ${({ theme }): string => theme.bgColors.BLUE_11};

  .ag-root-wrapper {
    border: 1px solid ${({ theme }): string => theme.borderColors.GREY_35};
    .ag-header-viewport {
      .ag-header-container {
        padding: 10px 0;
        .ag-header-row {
          border-top: 1px solid
            ${({ theme }): string => theme.borderColors.GREY_56};

          border-bottom: 1px solid
            ${({ theme }): string => theme.borderColors.GREY_56};
        }
      }
    }
  }
`;

export const NameRendererStyled = styled.div`
  display: flex;
  gap: 10px;

  .name-renderer {
    display: flex;
    align-items: center;
    column-gap: 5px;

    svg {
      margin-top: 15px;
      path {
        fill: ${({ theme }): string => theme.textColors.GREY_18};
      }
    }
  }
`;
export const CheckRendererStyled = styled(LinkButton)`
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding-left: 5px;

  .label-hierarchy {
    display: flex;
    align-items: center;
    column-gap: 4px;

    .hierarchy {
      color: ${({ theme }): string => theme.textColors.GREY_210};
      font-size: 13.6px;
    }
  }

  svg {
    path {
      fill: ${({ theme }): string => theme.bgColors.BLUE_116};
    }
  }
`;

export const EditAndFunnelIconWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding-left: 5px;
`;
