import { Tooltip } from "antd";
import { useCallback, useState } from "react";

import {
  ActionItemStyled,
  DropDownButtonStyled,
  DropDownWrapperStyled,
  ThreeDotActionMenuStyled,
  ThreeDotMenuButton,
} from "./threedotactionmenu.styles";
import { actionsIcon } from "../../svgs";

import { ThreeDotActionMenuProps } from "./threedotactionmenu.types";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";

const threeDotActionIcon = actionsIcon();

const ThreeDotActionMenu = (props: ThreeDotActionMenuProps): JSX.Element => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const { overLayItems } = props;

  const onVisibleChange = useCallback((visibility) => {
    setIsMenuVisible(visibility);
  }, []);

  return (
    <ThreeDotActionMenuStyled>
      <DropDownWrapperStyled isVisible={isMenuVisible}>
        <DropDownButtonStyled
          overlay={
            <>
              {overLayItems?.map((item) => {
                const { name, icon, onClick, customClass = "", disable } = item;

                const onMenuItemClick = (): void => {
                  onClick();
                  setIsMenuVisible(false);
                };

                return (
                  <Tooltip
                    key={`overlay-item-${name}`}
                    title={item?.desc}
                    placement="left"
                    overlayClassName={`${DVSUM_TOOLTIP_CLASS_NAME}`}
                  >
                    <ActionItemStyled
                      onClick={onMenuItemClick}
                      key={`overlay-item-${name}`}
                      className={`action-item ${customClass}`}
                      disabled={disable}
                    >
                      {icon} {name}
                    </ActionItemStyled>
                  </Tooltip>
                );
              })}
            </>
          }
          trigger={["click"]}
          placement="bottomRight"
          onVisibleChange={onVisibleChange}
          visible={isMenuVisible}
          getPopupContainer={(trigger): HTMLElement =>
            trigger.parentNode as HTMLElement
          }
        >
          <ThreeDotMenuButton
            className="actions-container"
            onClick={(): void => {
              setIsMenuVisible(!isMenuVisible);
            }}
            hasCommonStyle={false}
          >
            <div className="action-icon"> {threeDotActionIcon}</div>
          </ThreeDotMenuButton>
        </DropDownButtonStyled>
      </DropDownWrapperStyled>
    </ThreeDotActionMenuStyled>
  );
};

export default ThreeDotActionMenu;
