import { Tooltip } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import { SyntheticEvent, useCallback, useRef, useState } from "react";
import { ICellRendererParams } from "ag-grid-community";

import { TableProps } from "../../../../../../components/aggridtable/aggridtable.types";
import LinkButton from "../../../../../../components/linkbutton/linkbutton";

import { useOpenDrawer } from "../../../../../../customhooks";

import {
  CheckRendererStyled,
  EditAndFunnelIconWrapperStyled,
  NameRendererStyled,
} from "./addrulesetformgrid.styles";

import {
  AddRulesetFormGridColumnsType,
  AddRulesetFormGridRowFormType,
  HeaderCompParamsType,
} from "./addrulesetformgrid.types";

import {
  agGridStringComparator,
  getDataTypeIcon,
  isJsonString,
  jsonParse,
  numberFormatter,
} from "../../../../../../utils";

import Checkbox from "../../../../../../components/checkbox";
import { VerticalDividerStyled } from "../../../../../../components/dividers/dividers.styles";

import {
  editPencilIcon,
  filledFilterIcon,
  filterIconFlipped,
} from "../../../../../../svgs";

import { parsedFilters } from "../../../../../../components/filtercriteria/filtercriteria.config";

import { FilterConditionModal } from "../../../../../../components";

import { AddDataFormatFormType } from "../../../adddataformatform/adddataformatform.types";

import AddRuleFromRulesetGridModal from "../addrulefromrulesetgridmodal/addrulefromrulesetgridmodal";

import { AddValueRangeFormType } from "../../../addvaluerangeform/addvaluerangeform.types";
import { formDataToApiData } from "../../../addvaluerangeform/addvaluerangeformutil";

import { dataFormatFormDataToApiData } from "../../../adddataformatform/adddataformatformutil";

import { convertApiFiltersToUIData } from "./addrulesetformgrid.util";
import { rulesetFormFilterCriteriaSchema } from "../../../../../../utils/schemas/ruleschemas/rulesetschema";

import { RuleTypeId } from "../../../../../../parsers/ruleparser/ruleparser.types";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../../../../constants";

const unFilledFunnelIcon = filterIconFlipped("10", "10");
const editPencil = editPencilIcon("10", "10");
const filledFunnelIcon = filledFilterIcon("13", "13");

export const ruleSetFormGridColumns: TableProps<AddRulesetFormGridColumnsType>["tableColumns"] = [
  {
    field: "columnName",
    headerName: "Column Name",
    width: 300,
    comparator: agGridStringComparator,
  },
  {
    field: "minMaxValue",
    headerName: "Min-Max Value",
    width: 300,
  },
  { field: "nullCount", headerName: "Null Count", width: 200 },
  { field: "blanksCheck", headerName: "Blanks Check", width: 200 },
  { field: "valueRangeCheck", headerName: "Value Range", width: 200 },
  {
    field: "dataFormatCheck",
    headerName: "Data Format",
    width: 200,
  },
];

export const ColumnNameRendrer = (props: ICellRendererParams): JSX.Element => {
  const openDrawer = useOpenDrawer();

  const columnType = props?.data?.columnType;
  const columnName = props?.value;

  const onNodeClick = useCallback((e: SyntheticEvent) => {
    e?.stopPropagation();
    openDrawer({
      drawerId: "column_ref",
      visible: true,
      drawerProps: { id: "" },
    });
  }, []);

  return (
    <NameRendererStyled>
      <LinkButton
        onClick={onNodeClick}
        title={columnName}
        fontSize="13px"
        className="name-renderer"
      >
        <span className="column-type">{getDataTypeIcon(columnType)}</span>
        {columnName}
      </LinkButton>
    </NameRendererStyled>
  );
};

export const BlankCheckRenderer = (props: ICellRendererParams): JSX.Element => {
  const { column } = props || {};

  const params = (column?.getColDef?.()?.headerComponentParams ||
    {}) as HeaderCompParamsType;

  const localFiltersLength = useRef(null);

  const { columnsData, onChangeMultipleRulesetChecks, rulesOnColumn } =
    params || {};

  const columnId = props?.data?.columnId || "";

  const fieldName = `BLK_${columnId}`;

  const ruleForCurrentColumn = rulesOnColumn?.[fieldName];

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isBlankCheckSelected, setIsBlankCheckSelected] = useState(
    !!ruleForCurrentColumn
  );

  const onHideFilterModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const onToggleFilterModalVisibility = useCallback(() => {
    setIsModalVisible((st) => !st);
  }, []);

  const blankCheckForm = useForm<AddRulesetFormGridRowFormType>({
    defaultValues: {
      filter_criteria: [],
    },
    resolver: yupResolver(rulesetFormFilterCriteriaSchema),
    mode: "onChange",
  });

  const { setValue, watch } = blankCheckForm;

  const onFiltersSubmitted = useCallback(
    (filter_values?: AddRulesetFormGridRowFormType) => {
      const userAddedFilters = filter_values?.filter_criteria || [];

      const updatedBlankRule = {
        [fieldName]: {
          ...ruleForCurrentColumn,
          col_filter: userAddedFilters,
          // INTRODUCED LOCAL FILTERS BECAUSE THEY DONT NEED PARSING
          local_filters: userAddedFilters,
        },
      };

      onChangeMultipleRulesetChecks({
        ...updatedBlankRule,
      });
    },
    [fieldName, ruleForCurrentColumn, onChangeMultipleRulesetChecks]
  );

  const onCheckBoxChange = useCallback(
    (e) => {
      const newAddedValue = e?.target?.checked
        ? {
            col_id: Number(columnId),
            rule_type: "BLK" as RuleTypeId,
          }
        : {};

      onChangeMultipleRulesetChecks({
        [`BLK_${columnId}`]: newAddedValue,
      });

      setIsBlankCheckSelected(e?.target?.checked);
    },
    [columnId, onChangeMultipleRulesetChecks]
  );

  const onFilterClearData = useCallback(() => {
    setValue("filter_criteria", []);
  }, []);

  const parseExistingFilters = convertApiFiltersToUIData(
    ruleForCurrentColumn?.col_filter
  );

  const savedApiFiltersLength = ruleForCurrentColumn?.saved_filters_length || 0;
  const savedLocalFilters = ruleForCurrentColumn?.local_filters || [];
  const savedLocalFiltersLength = savedLocalFilters?.length || 0;

  const filtersExists = savedApiFiltersLength || savedLocalFiltersLength;

  return (
    <CheckRendererStyled>
      <LinkButton
        tooltipProps={{
          title:
            "Identify exception records within a dataset where a specific field is not populated. Data can be fixed.",
          placement: "rightTop",
        }}
      >
        <Checkbox checked={isBlankCheckSelected} onChange={onCheckBoxChange} />
      </LinkButton>

      {isBlankCheckSelected && (
        <EditAndFunnelIconWrapperStyled>
          <VerticalDividerStyled height="8px" width="2px" marginRight="5px" />
          <LinkButton onClick={onToggleFilterModalVisibility}>
            {filtersExists ? filledFunnelIcon : unFilledFunnelIcon}
          </LinkButton>
        </EditAndFunnelIconWrapperStyled>
      )}

      <FormProvider {...blankCheckForm}>
        <FilterConditionModal
          key={`blank-check-filter-criteria-${fieldName}`}
          isEditMode
          isModalVisible={isModalVisible}
          onHandleCancel={onHideFilterModal}
          parsedFields={columnsData}
          parsedFilters={parsedFilters}
          existingFilters={
            savedLocalFiltersLength ? savedLocalFilters : parseExistingFilters
          }
          nameOfFilterComp="filter_criteria"
          propsOnHandleSave={onFiltersSubmitted}
          onClearData={onFilterClearData}
        />
      </FormProvider>
    </CheckRendererStyled>
  );
};

export const ValueRangeCheckRenderer = (
  props: ICellRendererParams
): JSX.Element => {
  const columnId = props?.data?.columnId || "";

  const { column } = props || {};

  const params = (column?.getColDef?.()?.headerComponentParams ||
    {}) as HeaderCompParamsType;

  const {
    tableId,
    columnsData,
    onChangeMultipleRulesetChecks,
    rulesOnColumn,
    isEdit,
  } = params || {};
  const fieldName = `VLR_${columnId}`;

  const ruleForCurrentColumn = rulesOnColumn?.[fieldName];

  const valueRangeForm = useForm<AddValueRangeFormType>({
    defaultValues: {
      filter_criteria: [],
    },
    resolver: yupResolver(rulesetFormFilterCriteriaSchema),
    mode: "onChange",
  });

  const { setValue, watch } = valueRangeForm;

  const { filter_criteria: filterCriteria } = watch();

  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [isValueRangeModalVisible, setIsValueRangeModalVisible] = useState(
    false
  );

  const [isValueRangeSelected, setIsValueRangeSelected] = useState(
    !!ruleForCurrentColumn
  );

  const onToggleFilterModalVisibility = useCallback(() => {
    setIsFilterModalVisible((st) => !st);
  }, []);

  const onToggleValueRangeModalVisibility = useCallback(() => {
    setIsValueRangeModalVisible((st) => !st);
  }, []);

  const onCheckBoxChange = useCallback(
    (e) => {
      if (e?.target?.checked) {
        e?.preventDefault?.();
        setIsValueRangeModalVisible(true);
      } else {
        setIsValueRangeSelected(false);

        onChangeMultipleRulesetChecks({
          [`VLR_${columnId}`]: {},
        });
      }
    },
    [columnId, onChangeMultipleRulesetChecks]
  );

  const onValueRangeFiltersSubmitted = useCallback(
    (filter_values?: AddRulesetFormGridRowFormType) => {
      const userAddedFilters = filter_values?.filter_criteria || [];

      const updatedValueRange = {
        [fieldName]: {
          ...ruleForCurrentColumn,
          col_filter: userAddedFilters,
          local_filters: userAddedFilters,
        },
      };

      onChangeMultipleRulesetChecks({
        ...updatedValueRange,
      });
    },
    [ruleForCurrentColumn, fieldName, onChangeMultipleRulesetChecks]
  );

  const onValueRangeFormValueSubmitted = useCallback(
    (values: AddValueRangeFormType) => {
      setIsValueRangeModalVisible(false);
      setIsValueRangeSelected(true);

      const newValueRangeRuleOnColumn = formDataToApiData(values);

      const newRulesOnColumn = {
        [`VLR_${columnId}`]: newValueRangeRuleOnColumn,
      };

      onChangeMultipleRulesetChecks({
        ...newRulesOnColumn,
      });
    },
    [columnId, onChangeMultipleRulesetChecks]
  );

  const onFilterClearData = useCallback(
    () => setValue("filter_criteria", []),
    []
  );

  const parseExistingFilters = convertApiFiltersToUIData(
    ruleForCurrentColumn?.col_filter
  );

  const currentColumn = columnsData?.find(
    (item) => Number(item?.field_id) === Number(columnId)
  );

  const currentColumnDefinitionConfig = {
    col_id: Number(columnId),
    ...ruleForCurrentColumn,
    min_value: ruleForCurrentColumn
      ? ruleForCurrentColumn?.min_value
      : currentColumn?.col_min_value,
    max_value: ruleForCurrentColumn
      ? ruleForCurrentColumn?.max_value
      : currentColumn?.col_max_value,
    col_date_type: currentColumn?.field_datatype,
  };

  const savedApiFiltersLength = ruleForCurrentColumn?.saved_filters_length || 0;
  const savedLocalFilters = ruleForCurrentColumn?.local_filters || [];
  const savedLocalFiltersLength = savedLocalFilters?.length || 0;

  const filtersExists = savedApiFiltersLength || savedLocalFiltersLength;

  return (
    <CheckRendererStyled>
      <Checkbox checked={isValueRangeSelected} onChange={onCheckBoxChange} />
      {isValueRangeSelected && (
        <EditAndFunnelIconWrapperStyled>
          <LinkButton onClick={onToggleValueRangeModalVisibility}>
            {editPencil}
          </LinkButton>

          <VerticalDividerStyled height="8px" width="1px" marginRight="5px" />

          <LinkButton onClick={onToggleFilterModalVisibility}>
            {filtersExists ? filledFunnelIcon : unFilledFunnelIcon}
          </LinkButton>
        </EditAndFunnelIconWrapperStyled>
      )}

      <AddRuleFromRulesetGridModal
        title="Value Range Check"
        isModalVisible={isValueRangeModalVisible}
        onHandleCancel={onToggleValueRangeModalVisibility}
        propsOnValueRangeFormSubmit={onValueRangeFormValueSubmitted}
        formId="VLR"
        tableId={tableId}
        selectedColumnId={columnId}
        currentColumnDefinitionConfig={currentColumnDefinitionConfig}
      />
      <FormProvider {...valueRangeForm}>
        <FilterConditionModal
          key={`value-range-filter-criteria-${fieldName}`}
          isEditMode
          isModalVisible={isFilterModalVisible}
          onHandleCancel={onToggleFilterModalVisibility}
          parsedFields={columnsData}
          parsedFilters={parsedFilters}
          existingFilters={
            savedLocalFiltersLength ? savedLocalFilters : parseExistingFilters
          }
          nameOfFilterComp="filter_criteria"
          propsOnHandleSave={onValueRangeFiltersSubmitted}
          onClearData={onFilterClearData}
        />
      </FormProvider>
    </CheckRendererStyled>
  );
};

export const DataFormatCheckRenderer = (
  props: ICellRendererParams
): JSX.Element => {
  const columnId = props?.data?.columnId || "";

  const { column } = props || {};

  const params = (column?.getColDef?.()?.headerComponentParams ||
    {}) as HeaderCompParamsType;

  const {
    tableId,
    columnsData,
    onChangeMultipleRulesetChecks,
    rulesOnColumn,
    rulesRef,
    srcTypeId,
  } = params || {};

  const dataFormatRule = rulesRef?.find((rule) => rule?.id === "DAF");
  const dataFormatAdhocInfo = dataFormatRule?.adhocInfo;
  const parsedAdhocInfo = isJsonString(dataFormatAdhocInfo || "")
    ? jsonParse(dataFormatAdhocInfo || "")
    : {};

  const nonSupportingSources = parsedAdhocInfo?.non_supporting_src_types || [];
  const isNonSupportedSrc = nonSupportingSources?.includes(srcTypeId);

  const fieldName = `DAF_${columnId}`;

  const ruleForCurrentColumn = rulesOnColumn?.[fieldName];

  const currentColumnDetails = columnsData?.find(
    (item) => item?.field_id === columnId
  );

  const isStringTypeColumn = currentColumnDetails?.field_datatype === "STR";

  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [isDataFormatModalVisible, setIsDataFormatModalVisible] = useState(
    false
  );

  const [isDataFormatSelected, setIsDataFormatSelected] = useState(
    !!ruleForCurrentColumn
  );

  const onToggleDataFormatModalVisibility = useCallback(() => {
    setIsDataFormatModalVisible((st) => !st);
  }, []);

  const onToggleFilterModalVisibility = useCallback(() => {
    setIsFilterModalVisible((st) => !st);
  }, []);

  const defaultValuesObj = {
    filter_criteria: [],
  };

  const form = useForm({
    defaultValues: defaultValuesObj,
    resolver: yupResolver(rulesetFormFilterCriteriaSchema),
    mode: "onChange",
  });

  const { setValue, watch } = form;

  const { filter_criteria: filterCriteria } = watch();

  const onCheckBoxChange = useCallback(
    (e) => {
      if (e?.target?.checked) {
        e?.preventDefault?.();
        setIsDataFormatModalVisible(true);
      } else {
        const newRulesOnColumn = {
          [`DAF_${columnId}`]: {},
        };
        setIsDataFormatSelected(false);
        onChangeMultipleRulesetChecks({
          ...newRulesOnColumn,
        });
      }
    },
    [columnId, onChangeMultipleRulesetChecks]
  );

  const onDataFormatValueSubmitted = useCallback(
    (values: AddDataFormatFormType) => {
      setIsDataFormatModalVisible(false);
      setIsDataFormatSelected(true);
      const newAddedValue = dataFormatFormDataToApiData(values);

      const newRulesOnColumn = {
        [`DAF_${columnId}`]: newAddedValue,
      };

      onChangeMultipleRulesetChecks({
        ...newRulesOnColumn,
      });
    },
    [columnId, onChangeMultipleRulesetChecks]
  );

  const onDataFormatFiltersSubmitted = useCallback(
    (filter_values?: AddRulesetFormGridRowFormType) => {
      const userAddedFilters = filter_values?.filter_criteria || [];

      const updatedDataFormat = {
        [fieldName]: {
          ...ruleForCurrentColumn,
          col_filter: userAddedFilters,
          local_filters: userAddedFilters,
        },
      };

      onChangeMultipleRulesetChecks({
        ...updatedDataFormat,
      });
    },
    [ruleForCurrentColumn, fieldName, onChangeMultipleRulesetChecks]
  );

  const onFilterClearData = useCallback(
    () => setValue("filter_criteria", []),
    []
  );

  const parseExistingFilters = convertApiFiltersToUIData(
    ruleForCurrentColumn?.col_filter
  );

  const currentColumnDefinitionConfig = {
    col_id: Number(columnId),
    min_length: ruleForCurrentColumn?.min_length,
    max_length: ruleForCurrentColumn?.max_length,
    max_precision: ruleForCurrentColumn?.max_precision,
    data_type: ruleForCurrentColumn?.data_type,
    pattern: ruleForCurrentColumn?.pattern,
  };

  const savedApiFiltersLength = ruleForCurrentColumn?.saved_filters_length || 0;
  const savedLocalFilters = ruleForCurrentColumn?.local_filters || [];
  const savedLocalFiltersLength = savedLocalFilters?.length || 0;
  const filtersExists = savedApiFiltersLength || savedLocalFiltersLength;

  const isDataFormatRuleDisabled = !isStringTypeColumn || isNonSupportedSrc;

  return (
    <CheckRendererStyled>
      <Tooltip
        title={
          isNonSupportedSrc
            ? "This Rule is not supported by this Data Source."
            : !isStringTypeColumn
            ? "Data format rules are supported only for string data types."
            : undefined
        }
        placement="top"
        overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
      >
        <Checkbox
          checked={isDataFormatSelected}
          onChange={onCheckBoxChange}
          disabled={isDataFormatRuleDisabled}
        />
      </Tooltip>

      {isDataFormatSelected && (
        <EditAndFunnelIconWrapperStyled>
          <LinkButton onClick={onToggleDataFormatModalVisibility}>
            {editPencil}
          </LinkButton>

          <VerticalDividerStyled height="8px" width="1px" marginRight="5px" />

          <LinkButton onClick={onToggleFilterModalVisibility}>
            {filtersExists ? filledFunnelIcon : unFilledFunnelIcon}
          </LinkButton>
        </EditAndFunnelIconWrapperStyled>
      )}
      <AddRuleFromRulesetGridModal
        title="Data Format Check"
        isModalVisible={isDataFormatModalVisible}
        onHandleCancel={onToggleDataFormatModalVisibility}
        propsOnDataFormFormSubmit={onDataFormatValueSubmitted}
        formId="DAF"
        tableId={tableId}
        selectedColumnId={columnId}
        currentColumnDefinitionConfig={currentColumnDefinitionConfig}
      />
      <FormProvider {...form}>
        <FilterConditionModal
          key={`data-format-filter-criteria-${fieldName}`}
          isEditMode
          isModalVisible={isFilterModalVisible}
          onHandleCancel={onToggleFilterModalVisibility}
          parsedFields={columnsData}
          parsedFilters={parsedFilters}
          existingFilters={
            savedLocalFiltersLength ? savedLocalFilters : parseExistingFilters
          }
          nameOfFilterComp="filter_criteria"
          propsOnHandleSave={onDataFormatFiltersSubmitted}
          onClearData={onFilterClearData}
        />
      </FormProvider>
    </CheckRendererStyled>
  );
};

export const BlanksNumberRenderer = (
  props: ICellRendererParams
): JSX.Element => {
  const formattedNumber = (props?.value || 0)?.toString();

  return <>{props?.value === "" ? "0" : numberFormatter(formattedNumber)}</>;
};
