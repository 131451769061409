import { APP_ROUTES, ELEMENT_IDS } from "../../constants";
import { getDataImportURL } from "../../pages/dataimportpage/dataimportpage.utils";

import {
  analyticsDictionaryIcon,
  analyticsIcon,
  businessGlossaryIcon,
  chatNavIcon,
  dataDictionaryIcon,
  fieldDictionaryIcon,
  homeIcon,
  rulesDictionaryIcon,
  settingsCogsIcon,
} from "../../svgs";

import { RouteFace } from "./navbar.interfaces";

const {
  anldict_lftnav: ANLDICT_LFTNAV,
  busgls_lftnav: BUSGLS_LFTNAV,
  flddict_lftnav: FLDDICT_LFTNAV,
  rlsdict_lftnav: RLSDICT_LFTNAV,
  dataadm_lftnav: DATAADM_LFTNAV,
  datsrc_lftnav: DATSRC_LFTNAV,
  datdict_lftnav: DATDICT_LFTNAV,
  useradm_grp_lst_lftnv: USERADM_GRP_LST_LFTNV,
  useradm_rol_lst_lftnv: USERADM_ROL_LST_LFTNV,
  useradm_usr_lst_lftnv: USERADM_USR_LST_LFTNV,
  userjrn_per_hom_lftnav: USERJRN_PER_HOM_LFTNAV,
  dataadm_impexp_genimp_lftnav: DATAADM_IMPEXP_GENIMP_LFTNAV,
  rpt_sumrep_left_nav: RPT_SUMREP_LEFT_NAV,
  dataorg_tagset_left_nav: DATAORG_TAGSET_LEFT_NAV,
  caddi_lft_nav: CADDI_LFT_NAV,
  userjrn_onbrd_reference_dictionary_lft_nav: USERJRN_ONBRD_REFERENCE_DICTIONARY_LFT_NAV,
} = ELEMENT_IDS;

export function getSelectedMenuKey(
  pathname: keyof typeof APP_ROUTES.private_routes,
  isCollapsed: boolean
): string {
  switch (pathname) {
    case /^terms\/.*$/.test(pathname) && pathname:
      return isCollapsed
        ? "sidebar-glossary-terms-1.1"
        : "sidebar-glossary-terms-1";
    case (/^tables\/.*$/.test(pathname) && pathname) ||
      (/^tables$/.test(pathname) && pathname):
      return isCollapsed
        ? "sidebar-data-dictionary-3.1"
        : "sidebar-data-dictionary-3";
    case (/^columns\/.*$/.test(pathname) && pathname) ||
      (/^columns$/.test(pathname) && pathname):
      return isCollapsed
        ? "sidebar-field-dictionary-4.1"
        : "sidebar-field-dictionary-4";

    case (/^rules\/.*$/.test(pathname) && pathname) ||
      (/^rules$/.test(pathname) && pathname):
      return isCollapsed
        ? "sidebar-rules-dictionary-5.1"
        : "sidebar-rules-dictionary-5";

    case (/^analytics_dictionary\/.*$/.test(pathname) && pathname) ||
      (/^analytics_dictionary$/.test(pathname) && pathname) ||
      (/^data_sources\/.*$/.test(pathname) && pathname) ||
      (/^reports\/.*$/.test(pathname) && pathname):
      return isCollapsed
        ? "sidebar-analytics-dictionary-6.1"
        : "sidebar-analytics-dictionary-6";
    // case "glossary_categories":
    //   return isCollapsed
    //     ? "sidebar-glossary-categories-3.1"
    //     : "sidebar-glossary-categories-3";
    case "glossary_import":
      return isCollapsed
        ? "sidebar-glossary-import-2.1"
        : "sidebar-glossary-import-2";
    case (/^terms\/.*$/.test(pathname) && pathname) ||
      (/^terms$/.test(pathname) && pathname):
      return isCollapsed
        ? "sidebar-glossary-terms-1.1"
        : "sidebar-glossary-terms-1";
    case "profiling":
      return isCollapsed ? "sidebar-profiling-4.1" : "sidebar-profiling-4";
    case "user_groups":
      return "sidebar-administration-2.1";
    case "user_roles":
      return "sidebar-administration-2.2";
    case "users":
      return "sidebar-administration-2.3";
    // case "glossary_upload":
    //   return "sidebar-administration-2.5";
    case "data_domains":
      return "sidebar-administration-2.6";
    case "sub_domains":
      return "sidebar-administration-2.7";
    case "data_import":
      return "sidebar-administration-2.8";
    case "sources":
    case /^sources\/.*$/.test(pathname) && pathname:
      return "sidebar-data-sources-2.5";
    case "tagsets":
    case /^tagsets\/.*$/.test(pathname) && pathname:
      return "sidebar-administration-2.9";
    case "analytics":
    case /^analytics\/.*$/.test(pathname) && pathname:
      return "sidebar-administration-2.10";
    case "reference_dictionary":
    case /^reference\/.*$/.test(pathname) && pathname:
      return "sidebar-administration-2.13";
    case (/^jobs\/.*$/.test(pathname) && pathname) ||
      (/^jobs$/.test(pathname) && pathname):
      return "sidebar-administration-2.11";
    case (/^executions\/.*$/.test(pathname) && pathname) ||
      (/^executions$/.test(pathname) && pathname):
      return "sidebar-administration-2.12";
    case "home":
      return isCollapsed ? "sidebar-home-1.1" : "sidebar-home-1";
    case "chat":
      return isCollapsed ? "sidebar-chat-8.1" : "sidebar-chat-8";
    case /^account_settings\/.*$/.test(pathname) && pathname:
      return "sidebar-administration-2.4";
    default:
      return "sidebar-dashboard-1";
  }
}

export const getSideNavConfig = (
  isCollapsed?: boolean,
  isAnyAdmin?: boolean,
  isAnyEditor?: boolean,
  isGlossaryAdmin?: boolean,
  isUserAdmin?: boolean,
  isAccountAdmin?: boolean,
  isCatalogAdmin?: boolean,
  hasCatalogAccess?: boolean,
  hasGlossaryAccess?: boolean,
  isChatEnabled?: boolean,
  isDqEnabled?: boolean
): Array<RouteFace> => {
  return [
    {
      key: "sidebar-home-1",
      title: "Home",
      icon: homeIcon,
      link: APP_ROUTES.private_routes.home,
      id: USERJRN_PER_HOM_LFTNAV,
      subRoutes: isCollapsed
        ? [
            {
              key: "sidebar-home-1.1",
              title: "Home",
              link: APP_ROUTES.private_routes.home,
              id: USERJRN_PER_HOM_LFTNAV,
            },
          ]
        : undefined,
    },
    ...((isChatEnabled && [
      {
        key: "sidebar-chat-8",
        title: "Talk to Your Data",
        icon: chatNavIcon,
        link: APP_ROUTES.private_routes.new_chat,
        id: CADDI_LFT_NAV,
        openInNewTab: true,
        subRoutes: isCollapsed
          ? [
              {
                key: "sidebar-chat-8.1",
                title: "Talk to Your Data",
                link: APP_ROUTES.private_routes.new_chat,
                openInNewTab: true,
                id: CADDI_LFT_NAV,
              },
            ]
          : undefined,
      },
    ]) ||
      []),

    ...((hasCatalogAccess && [
      {
        key: "sidebar-data-dictionary-3",
        title: "Data Dictionary",
        icon: dataDictionaryIcon,
        link: APP_ROUTES.private_routes.table_dictionary,
        id: DATDICT_LFTNAV,
        subRoutes: isCollapsed
          ? [
              {
                key: "sidebar-data-dictionary-3.1",
                title: "Data Dictionary",
                link: APP_ROUTES.private_routes.table_dictionary,
                id: DATDICT_LFTNAV,
              },
            ]
          : undefined,
      },
      {
        key: "sidebar-field-dictionary-4",
        title: "Field Dictionary",
        icon: fieldDictionaryIcon,
        link: APP_ROUTES.private_routes.field_dictionary,
        id: FLDDICT_LFTNAV,
        subRoutes: isCollapsed
          ? [
              {
                key: "sidebar-field-dictionary-4.1",
                title: "Field Dictionary",
                link: APP_ROUTES.private_routes.field_dictionary,
                id: FLDDICT_LFTNAV,
              },
            ]
          : undefined,
      },

      ...((isDqEnabled &&
        hasCatalogAccess && [
          {
            key: "sidebar-rules-dictionary-5",
            title: "DQ Dictionary",
            icon: rulesDictionaryIcon,
            link: APP_ROUTES.private_routes.rules_dictionary,
            id: RLSDICT_LFTNAV,
            subRoutes: isCollapsed
              ? [
                  {
                    key: "sidebar-rules-dictionary-5.1",
                    title: "DQ Dictionary",
                    link: APP_ROUTES.private_routes.rules_dictionary,
                    id: RLSDICT_LFTNAV,
                  },
                ]
              : undefined,
          },
        ]) ||
        []),

      {
        key: "sidebar-analytics-dictionary-6",
        title: "Analytics Dictionary",
        icon: analyticsDictionaryIcon,
        link: APP_ROUTES.private_routes.analytics_dictionary,
        id: ANLDICT_LFTNAV,
        subRoutes: isCollapsed
          ? [
              {
                key: "sidebar-analytics-dictionary-6.1",
                title: "Analytics Dictionary",
                link: APP_ROUTES.private_routes.analytics_dictionary,
                id: ANLDICT_LFTNAV,
              },
            ]
          : undefined,
      },
    ]) ||
      []),

    ...((hasGlossaryAccess && [
      {
        key: "sidebar-glossary-terms-1",
        title: "Business Glossary",
        icon: businessGlossaryIcon,
        link: APP_ROUTES.private_routes.terms,
        id: BUSGLS_LFTNAV,
        subRoutes: isCollapsed
          ? [
              {
                key: "sidebar-glossary-terms-1.1",
                title: "Business Glossary",
                link: APP_ROUTES.private_routes.terms,
                id: BUSGLS_LFTNAV,
              },
            ]
          : undefined,
      },
    ]) ||
      []),

    ...((isAnyAdmin && [
      {
        key: "sidebar-administration-2",
        title: "Administration",
        icon: (): JSX.Element => settingsCogsIcon(),
        id: DATAADM_LFTNAV,
        subRoutes: [
          ...((isAccountAdmin && [
            {
              key: "sidebar-administration-2.4",
              title: "Account Settings",
              link: `${APP_ROUTES.private_routes.account_settings}/subscription`,
            },
            {
              key: "sidebar-administration-2.9",
              title: "Tags",
              link: APP_ROUTES.private_routes.tagsets,
              id: DATAORG_TAGSET_LEFT_NAV,
            },
            {
              key: "sidebar-administration-2.8",
              title: "Data Import",
              link: getDataImportURL(1),
              id: DATAADM_IMPEXP_GENIMP_LFTNAV,
            },
          ]) ||
            []),
          ...(((isAccountAdmin || isCatalogAdmin) && [
            {
              key: "sidebar-data-sources-2.5",
              title: "Data Sources",
              link: APP_ROUTES.private_routes.sources,
              id: DATSRC_LFTNAV,
            },
          ]) ||
            []),
          ...(((isUserAdmin || isAccountAdmin) && [
            {
              key: "sidebar-administration-2.1",
              title: "User Groups",
              link: APP_ROUTES.private_routes.user_groups,
              id: USERADM_GRP_LST_LFTNV,
            },
            {
              key: "sidebar-administration-2.2",
              title: "User Roles",
              link: APP_ROUTES.private_routes.user_roles,
              id: USERADM_ROL_LST_LFTNV,
            },
            {
              key: "sidebar-administration-2.3",
              title: "Users",
              link: APP_ROUTES.private_routes.users,
              id: USERADM_USR_LST_LFTNV,
            },
          ]) ||
            []),
          ...(((isGlossaryAdmin || isAccountAdmin) && [
            {
              key: "sidebar-administration-2.6",
              title: "Data Domains",
              link: APP_ROUTES.private_routes.data_domains,
            },
            {
              key: "sidebar-administration-2.7",
              title: "Data Sub-Domains",
              link: APP_ROUTES.private_routes.sub_domains,
            },
          ]) ||
            []),

          // ...(((isAnyEditor || isAnyAdmin) && [
          {
            key: "sidebar-administration-2.11",
            title: "Jobs",
            link: APP_ROUTES.private_routes.jobs_dictionary,
          },

          {
            key: "sidebar-administration-2.12",
            title: "Job Executions",
            link: APP_ROUTES.private_routes.jobexecutions_dictionary,
          },

          {
            key: "sidebar-administration-2.13",
            title: "Reference Dictionary",
            link: APP_ROUTES.private_routes.reference_dictionary,
            id: USERJRN_ONBRD_REFERENCE_DICTIONARY_LFT_NAV,
          },
          // ]) ||
          //   []),
          ...(((isAnyEditor || isAnyAdmin) && [
            {
              key: "sidebar-administration-2.10",
              title: "Usage Analytics",
              link: APP_ROUTES.private_routes.analytics,
            },
          ]) ||
            []),
        ],
      },
    ]) ||
      []),
  ];
};
