import parse from "html-react-parser";

export const parseStringToHtml = (
  text?: string
): JSX.Element | JSX.Element[] | undefined | string => {
  try {
    return parse(text || "");
  } catch (error) {
    return text || "";
  }
};
