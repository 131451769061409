//   <--Library Imports Start-->
import { useCallback, useEffect } from "react";
//   <--Library Imports Start-->

//   <--Custom Hooks Start-->
import { useCancelModal, useOpenDrawer, useOpenModal } from "../../customhooks";
//   <--Custom Hooks End-->

//   <--SVGS Start-->
import { editPencilIcon } from "../../svgs";
//   <--SVGS End-->

//   <--Styles Start-->
import { NodeRefQuickEditCompStyled } from "./noderefquickedit.styles";
//   <--Styles End-->

//   <--Types Start-->
import { TermRefDrawerProps } from "../../drawerviews/termrefdrawer/termrefdrawer.types";
import { QuickEditForColConfirmationModalProps } from "../modalwrapper/modals/quickeditforcolconfirmationmodal/quickeditforcolconfirmationmodal.types";

import { NodeRefQuickEditCompProps } from "./noderefquickedit.types";
import { ELEMENT_IDS } from "../../constants";
//   <--Types End-->

const {
  datdict_detpg_info_ref_edit_btn: DATDICT_DETPG_INFO_REF_EDIT_BTN,
} = ELEMENT_IDS;

const NodeRefQuickEditComp = (
  props: NodeRefQuickEditCompProps
): JSX.Element => {
  const {
    nodeDesc = "",
    nodeId = "",
    nodeTitle = "",
    nodeType,
    onClickEdit,
    right = "",
    top = "",
    nodeSubType,
    parentId = "",
    entityId = "",
    isColDescInherited,
    isTriggerEditOnMount,
    onChangeEditView,
    nodeName = "",
  } = props;

  const openModal = useOpenModal();
  const onCancel = useCancelModal();

  const openDrawer = useOpenDrawer();

  const onClickEditWithProps = useCallback(() => {
    if (nodeType === "COL" && isColDescInherited) {
      openModal({
        modalId: "quick_edit_for_col_confirmation",
        modalTitle: "Edit Column Details",
        visible: true,
        modalProps: {
          onEditEntity: () => {
            openDrawer({
              drawerId: "term_ref",
              drawerProps: { id: entityId, isEdit: true } as TermRefDrawerProps,
              visible: true,
            });
            onCancel();
          },
          onEditField: () => {
            onClickEdit?.({
              nodeType,
              nodeId,
              nodeTitle,
              nodeDesc,
              nodeSubType,
              parentId,
              isUpdatingInheritedDesc: true,
              nodeName,
            });
            onCancel();
          },
        } as QuickEditForColConfirmationModalProps,
      });
    } else {
      onClickEdit?.({
        nodeType,
        nodeId,
        nodeTitle,
        nodeDesc,
        nodeSubType,
        parentId,
        nodeName,
      });
    }
  }, [props]);

  useEffect(() => {
    isTriggerEditOnMount && onClickEditWithProps();
    onChangeEditView?.();
  }, []);

  return (
    <NodeRefQuickEditCompStyled right={right} top={top}>
      <span
        className="edit-pencil"
        role="button"
        onClick={onClickEditWithProps}
        id={DATDICT_DETPG_INFO_REF_EDIT_BTN}
      >
        {editPencilIcon("13", "13")}
      </span>
    </NodeRefQuickEditCompStyled>
  );
};

export default NodeRefQuickEditComp;
