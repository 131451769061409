import { useCallback, useMemo } from "react";

import LinkButton from "../linkbutton";
import ListItemsView from "../listitemsview/listitemsview";

import PageHeader from "../pageheader";
import Flex from "../flex";

import {
  GlossaryPageHeaderStyled,
  RulePageLeftSectionStyled,
} from "./glossarypageheader.styles";

import {
  iconChevronDownLarge,
  iconDownload,
  importIcon,
  multiChatFilledIcon,
  saveIcon,
  shareIconWithoutBorder,
  toReviewFilledSvg,
} from "../../svgs";

import { GlossaryPageHeaderProps } from "./glossarypageheader.types";
import { ELEMENT_IDS } from "../../constants";

import { useGetAppState } from "../../customhooks";
import MultiLevelDropDownMenu from "../multileveldropdownmenu";

import { useGetRefByType } from "../../api/refservice";
import { getParsedDqRulesCategoriesAndTypes } from "./glossarypageheader.util";

import { VerticalDividerStyled } from "../dividers/dividers.styles";
import { isJsonString, jsonParse } from "../../utils";
import { getPageNode } from "../../pages/listingpage/listingpage.utils";

import { ViewSelector } from "..";
import { ViewSelectorOptionsType } from "../viewselector/viewselector.types";

const {
  allcat_lst_lst_export_btn: ALLCAT_LST_LST_EXPORT_BTN,
  allcat_lst_lst_get_views_btn: ALLCAT_LST_LST_GET_VIEWS_BTN,
  allcat_lst_lst_view_ttl: ALLCAT_LST_LST_VIEW_TTL,
  allcat_lst_lst_share_btn: ALLCAT_LST_LST_SHARE_BTN,
  allcat_lst_lst_create_btn: ALLCAT_LST_LST_CREATE_BTN,
  cht_bot_anal_tab_cht_lst: CHT_BOT_ANAL_TAB_CHT_LST,
  cht_bot_anal_tab_fbk_lst: CHT_BOT_ANAL_TAB_FBK_LST,
} = ELEMENT_IDS;

function GlossaryPageHeader(props: GlossaryPageHeaderProps): JSX.Element {
  const {
    data,
    title,
    onClick,
    onClickCreateView,
    sectionTitle,
    isTitleStatic = false,
    hideRightSection = false,
    stateHandlerProps,
    download,
    dataImport,
    save,
    share,
    isDefaultView = false,
    isViewer = false,
    leftSecButtons = [],
    hideLeftSideActions,
    nodeType,
    onChangeHeaderRightView,
    customRightSecOutsideListingViews,
    isOutsideListingVews = false,
    tableId = "",
    sourceId = 0,
    srcTypeId,
    isOnlineProfilingInProgress = false,
    freshnessRuleColumnId,
    isExpandedGridView = false,
  } = props;

  const { parsedData: dqRulesCategoriesAndTypes } = useGetRefByType(
    "DQ_RULE_TYPES"
  );

  const { modal } = useGetAppState();

  const { isJobPage, isRulePage, isJobExecutionsPage } = getPageNode(nodeType);

  const isProfilingInProgressAndRulePage =
    isRulePage && isOutsideListingVews && isOnlineProfilingInProgress;

  const getNodeName = useCallback(() => {
    const nodeLabels = {
      TBL: "Tables",
      COL: "Columns",
      RLS: "Rules",
      TRM: "Terms",
      DSR: "Datasets",
      JOB: "Jobs",
      SCH: "Executions",
    };
    return nodeLabels[nodeType as keyof typeof nodeLabels];
  }, [nodeType]);

  const isDqTabLisiting = isOutsideListingVews && isRulePage;

  const isFreshnessRuleDisabled = !freshnessRuleColumnId;

  const dqRules = useMemo(() => {
    return dqRulesCategoriesAndTypes?.map((rule) => {
      const { adhocInfo } = rule;

      const parsedAdhocInfo = isJsonString(adhocInfo || "")
        ? jsonParse(adhocInfo || "")
        : {};

      const nonSupportingSrcTypes =
        parsedAdhocInfo?.non_supporting_src_types || [];

      const isRuleDisabled =
        (rule?.id === "FRC" && isFreshnessRuleDisabled) ||
        nonSupportingSrcTypes?.includes(srcTypeId);

      return {
        ...rule,
        disabled: isRuleDisabled,
      };
    });
  }, [dqRulesCategoriesAndTypes, srcTypeId, isFreshnessRuleDisabled]);

  const parsedDqRules = getParsedDqRulesCategoriesAndTypes(dqRules);

  const renderLeftSectionButtons = useMemo(() => {
    return leftSecButtons?.map((button) => {
      const {
        isHide,
        disabled,
        icon,
        onClick,
        title,
        customClass,
        id = "",
        tooltipProps,
      } = button;
      return (
        !isHide && (
          <LinkButton
            className={customClass}
            onClick={onClick}
            title={title}
            disabled={disabled}
            elementId={id}
            tooltipProps={tooltipProps}
          >
            {icon}
          </LinkButton>
        )
      );
    });
  }, [leftSecButtons]);

  const chatViewSelectorOptions = useMemo(
    (): ViewSelectorOptionsType => [
      {
        id: "CHT",
        tooltipProps: { title: "Chats Analytics", placement: "topLeft" },
        iconOrLabelJsx: multiChatFilledIcon(),
        isActive: nodeType === "CHT",
        className: "chats-listing",
        onClick: onChangeHeaderRightView as ViewSelectorOptionsType[number]["onClick"],
        elementId: CHT_BOT_ANAL_TAB_CHT_LST,
      },
      {
        id: "FBK",
        tooltipProps: { title: "Feedback Analytics", placement: "topLeft" },
        iconOrLabelJsx: toReviewFilledSvg(),
        isActive: nodeType === "FBK",
        className: "feedback-analytics",
        onClick: onChangeHeaderRightView as ViewSelectorOptionsType[number]["onClick"],
        elementId: CHT_BOT_ANAL_TAB_FBK_LST,
      },
    ],
    [nodeType, onChangeHeaderRightView]
  );

  const pageHeaderLeftComponent = isExpandedGridView ? (
    <span className="node-static-text">{title} </span>
  ) : isTitleStatic ? (
    <span className="gov-view-static-text">{title}</span>
  ) : (
    <>
      {/* THIS IS GOV VIEWS DROPDOWN */}
      {!isDqTabLisiting && (
        <ListItemsView
          data={data}
          sectionTitle={sectionTitle}
          selected={title}
          onClick={onClick}
          onClickCreateView={onClickCreateView}
          isViewer={isViewer}
          createViewElemId={ALLCAT_LST_LST_CREATE_BTN}
        >
          <LinkButton
            disabled={save?.isLoading || isOnlineProfilingInProgress}
            elementId={ALLCAT_LST_LST_GET_VIEWS_BTN}
          >
            <span id={ALLCAT_LST_LST_VIEW_TTL} className="gov-view-static-text">
              {title} {iconChevronDownLarge}
            </span>
          </LinkButton>
        </ListItemsView>
      )}
      {/* THIS IS EDIT GOV VIEW, REFRESH AND ADD TERM/ADD JOB MENU ITEMS, HIDDEN WHEN IT IS OUTSIDE LISTING VIEWS */}

      <Flex columnGap={16} customClass="gph-action-buttons">
        {renderLeftSectionButtons}
      </Flex>
      {/* THIS IS HEADING(RULES) + ADD NEW RULE MENU ITEM IN DQ TAB BUT OUTSIDE LISTING VIEWS */}
      {isDqTabLisiting && (
        <RulePageLeftSectionStyled>
          <div className="page-name">Rules</div>
          <VerticalDividerStyled height="8px" />
          <MultiLevelDropDownMenu
            data={parsedDqRules}
            tableId={tableId}
            sourceId={sourceId}
            srcTypeId={srcTypeId}
            isOnlineProfilingInProgress={isOnlineProfilingInProgress}
            freshnessRuleColumnId={freshnessRuleColumnId}
          />
        </RulePageLeftSectionStyled>
      )}
    </>
  );

  const pageHeaderRightComponent =
    (nodeType === "CHT" || nodeType === "FBK") && !isExpandedGridView ? (
      <div className="toggle-view">
        <ViewSelector
          label="View"
          viewSelectorOptions={chatViewSelectorOptions}
        />
      </div>
    ) : !hideRightSection && !isExpandedGridView ? (
      // THIS IS DOWNLOAD, IMPORT, SAVE AND SHARE BUTTONS ON LEFT SIDE OF LISTING, WILL BE HIDDEN OUTSIDE LISTING

      <>
        <LinkButton
          className="download-icon"
          onClick={download?.onClick}
          disabled={download?.isLoading || isOnlineProfilingInProgress}
          elementId={modal?.visible ? "" : ALLCAT_LST_LST_EXPORT_BTN}
        >
          {iconDownload("16.24px", "16.7px")} Download
        </LinkButton>
        {!isRulePage && !isJobPage && !isJobExecutionsPage && (
          <>
            <LinkButton
              className="import-icon"
              onClick={dataImport?.onClick}
              disabled={
                dataImport?.isDisabled ||
                isViewer ||
                isOnlineProfilingInProgress
              }
            >
              {importIcon("16.24px", "16.7px")} Import
            </LinkButton>

            {!isJobPage && !isJobExecutionsPage && (
              <LinkButton
                className="save-icon"
                onClick={save?.onClick}
                disabled={
                  isDefaultView ||
                  save?.isLoading ||
                  isViewer ||
                  isOnlineProfilingInProgress
                }
              >
                {saveIcon("9.92px", "16.7px")} Save
                {save?.isFavourite && "d"}
              </LinkButton>
            )}
          </>
        )}

        <LinkButton
          onClick={share?.onClick}
          disabled={
            isDefaultView ||
            share?.isLoading ||
            isViewer ||
            isOnlineProfilingInProgress
          }
          elementId={ALLCAT_LST_LST_SHARE_BTN}
          className="share-icon"
        >
          {shareIconWithoutBorder("16.8px", "16.7px")} Share
        </LinkButton>
      </>
    ) : (
      //  ADDING SEARCH BAR AND AVB/RCM SWITCH IN DQ LISTING TAB/SEARCH IN JOB DETAIL PAGE
      <>
        {!!customRightSecOutsideListingViews &&
          customRightSecOutsideListingViews}
      </>
    );

  return (
    <GlossaryPageHeaderStyled
      isProfilingInProgress={isProfilingInProgressAndRulePage}
      isDqTabOutSideListing={!!isDqTabLisiting}
    >
      <PageHeader
        padding="0px"
        stateHandlerProps={stateHandlerProps}
        leftComponent={pageHeaderLeftComponent}
        rightComponent={pageHeaderRightComponent}
      />
    </GlossaryPageHeaderStyled>
  );
}

export default GlossaryPageHeader;
