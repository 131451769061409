import { Card } from "antd";

import {
  LineChart as Chart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";

import { LineChartStyled } from "./linechart.styles";
import { LineChartProps } from "./linechart.types";

import RechartHOC from "../recharthoc/recharthoc";
import { RechartHOCEmbedProps } from "../recharthoc/recharthoc.types";

import { numberFormatter } from "../../../utils";
import { DEFAULT_MARGIN } from "../defaultmargin";

import { DEFAULT_X_AXIS_LABEL } from "../defaultxaxislabel";
import { DEFAULT_Y_AXIS_LABEL } from "../defaultyaxislabel";

import CustomizedAxisTick from "../customizedaxistick";
import { getYAxisMaxValWithNearestRoundedVal } from "../rechart.utills";

const LineChartContent = (
  props: LineChartProps & RechartHOCEmbedProps
): JSX.Element => {
  const {
    width = 450,
    height = 500,
    data = [],
    xAxisLabel,
    legends,
    clonedDataKeys = [],
    margin,
    updatedYAxis,
    isMultipleYAxis,
    isTiltedXAxisTicks = false,
    hideXAxis = false,
    showCartesianGrid = true,
    hideYAxis = false,
    onClick,
    onDomainLoad,
    chartYAxisLeftOffset = 0,
    chartInnerSpaceFromLeft = 20,
    tooltipFormatter,
    yAxisFormatter,
    xAxisTickFormatter,
    tooltipLabelFormatter,
    footerComponent,
  } = props;

  return (
    <Card>
      <LineChartStyled>
        <ResponsiveContainer width={width} height={height}>
          <Chart
            data={data}
            margin={{
              ...DEFAULT_MARGIN,
              left: chartYAxisLeftOffset - chartInnerSpaceFromLeft,
              ...margin,
            }}
            onClick={onClick}
          >
            {showCartesianGrid && (
              <CartesianGrid
                strokeDasharray="2"
                vertical={false}
                stroke="#e6e6e6"
              />
            )}
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={{ stroke: "#e6e6e6" }}
              tickMargin={10}
              padding={{ left: 20, right: 20 }}
              height={60}
              hide={hideXAxis}
              tick={
                isTiltedXAxisTicks ? (
                  <CustomizedAxisTick
                    textAnchor="end"
                    fill="#8A8C8C"
                    transform="rotate(-20)"
                  />
                ) : (
                  { fill: "#8A8C8C" }
                )
              }
              tickFormatter={xAxisTickFormatter}
            >
              {xAxisLabel && (
                <Label {...{ ...DEFAULT_X_AXIS_LABEL, ...xAxisLabel }} />
              )}
            </XAxis>
            {updatedYAxis?.map((item) => (
              <YAxis
                axisLine={false}
                tickLine={false}
                tickMargin={12}
                tick={{ fill: "#8A8C8C", fillOpacity: "0.87" }}
                hide={hideYAxis || item?.hide}
                orientation={item?.orientation}
                allowDecimals={item?.allowDecimals || true}
                {...(isMultipleYAxis && {
                  ...item,
                  label: undefined,
                })}
                key={`line-chart-y-axis-${item?.yAxisId}`}
                tickFormatter={(val): string => {
                  onDomainLoad?.(val);
                  return `${item?.prefix || ""}${
                    yAxisFormatter?.(val, item?.yAxisId || "") ||
                    numberFormatter(val)
                  }${item?.suffix || ""}`;
                }}
                {...(item?.hide
                  ? {
                      domain: ([min, max]): [number, number] => {
                        const minValue = item?.minYDomain || min || 0;
                        const maxVAlue = item?.maxYDomain || max || 0;

                        const finiteMinValue = Number.isFinite(minValue)
                          ? minValue
                          : 0;

                        const finiteMaxValue = Number.isFinite(maxVAlue)
                          ? maxVAlue
                          : 0;

                        const maxValueWithBuffer = getYAxisMaxValWithNearestRoundedVal(
                          finiteMaxValue
                        );

                        return [finiteMinValue, maxValueWithBuffer];
                      },
                    }
                  : { domain: item?.domain || ["auto", "auto"] })}
              >
                {item?.label && (
                  <Label
                    {...{
                      ...DEFAULT_Y_AXIS_LABEL,
                      ...item?.label,
                    }}
                  />
                )}
              </YAxis>
            ))}
            <Tooltip
              formatter={(val, name): string => {
                const prefix =
                  updatedYAxis?.find((item) => item?.yAxisId === name)
                    ?.prefix || "";

                const suffix =
                  updatedYAxis?.find((item) => item?.yAxisId === name)
                    ?.suffix || "";

                return `${prefix}${
                  tooltipFormatter?.(Number(val), `${name}`) ||
                  numberFormatter(`${val}`, true)
                }${suffix}`;
              }}
              labelFormatter={tooltipLabelFormatter}
              separator=": "
            />
            {legends}
            {clonedDataKeys?.map((lineItem, index) => {
              return (
                <Line
                  type="monotone"
                  dataKey={lineItem.dataKey}
                  stroke={lineItem?.fill}
                  strokeWidth={2}
                  {...(isMultipleYAxis && {
                    yAxisId: lineItem?.dataKey?.toString(),
                  })}
                  activeDot={{ r: 8 }}
                  key={`line-chart-data-key-${index}`}
                />
              );
            })}
          </Chart>
        </ResponsiveContainer>
        {footerComponent}
      </LineChartStyled>
    </Card>
  );
};

const LineChart = (props: LineChartProps): JSX.Element => {
  return (
    <RechartHOC
      dataKeys={props?.dataKeys}
      yAxis={props?.yAxis}
      showLedgends={props?.showLedgends}
    >
      <LineChartContent {...props} />
    </RechartHOC>
  );
};

export default LineChart;
