//  <--Library Imports Start-->
import { useMemo } from "react";
import { LineChart as Chart } from "@k2/rv-viz";

import { addMinutes, format, getDay, subMinutes } from "date-fns";
//  <--Library Imports End-->

//  <--Components Start-->
import LinkButton from "../../linkbutton";
//  <--Components End-->

//  <-- Styles Start-->
import { LineChartStyled } from "./linechart.styles";
//  <-- Styles End-->

//  <--SVGS Start-->
import { collapsedIconInCircle } from "../../../svgs";
//  <--SVGS End-->

//  <--Types Start-->
import { LineChartProps } from "./linechart.types";
//  <--Types End-->

//  <--Utils Start-->
import { calculateElementSize } from "../../../utils";
//  <--Utils End-->

const yAxis = {
  tickPadding: 0,
  tickSize: 12,
  tickTotal: 5,
  style: {
    line: { stroke: "#e7e7e7", strokeWidth: 1 },
    text: { stroke: "none", fill: "#777777", fontSize: "14px" },
  },
};

const xAxis = {
  style: {
    line: { stroke: "#e7e7e7", strokeWidth: 1 },
    ticks: { stroke: "#e7e7e7", strokeWidth: 1 },
    text: { stroke: "none", fill: "#777777", fontSize: "14px" },
  },
  position: "start",
  tickPadding: 0,
  tickSize: 12,
  tickTotal: 5,
};

function LineChart(props: LineChartProps): JSX.Element {
  const { data = [], xyPlot = {} } = props;

  const updatedData = data?.map((item) => {
    const { data: itemData = [] } = item || {};

    const isOnlyOneItemExists = itemData?.length === 1;
    const firstItem: any = isOnlyOneItemExists ? itemData?.[0] : { x: 0, y: 0 };

    const dayOfOriginalDate = getDay(new Date(firstItem?.x || ""));

    const afterAddingOnerMin = addMinutes(new Date(firstItem?.x || ""), 1);
    const afterRemovingOnerMin = subMinutes(new Date(firstItem?.x || ""), 1);

    const dayAfterAddOneMin = getDay(afterAddingOnerMin);

    return {
      ...item,
      data: [
        ...itemData,
        ...(isOnlyOneItemExists && firstItem
          ? [
              {
                x:
                  dayOfOriginalDate === dayAfterAddOneMin
                    ? new Date(afterAddingOnerMin)?.getTime()
                    : new Date(afterRemovingOnerMin)?.getTime(),
                y: firstItem?.y,
              },
            ]
          : []),
      ],
    };
  });

  const maxYAxisVal = useMemo(() => {
    const combinedData: any[] =
      data?.map((item) => item?.data || [])?.flat() || [];

    const combinedYAxesValues =
      combinedData?.map((item: { y: number }) => item.y) || [];

    const maxYAxisValue = Math?.max(...combinedYAxesValues);
    const updatedMaxYAxisVal = maxYAxisValue + maxYAxisValue / 2;
    return {
      maxValForDomain: updatedMaxYAxisVal,
      maxValForWidth: maxYAxisValue,
    };
  }, [data]);

  const maxWidthOfYAxisVal = useMemo(() => {
    const width =
      calculateElementSize(`${maxYAxisVal?.maxValForWidth}`, {
        fontSize: "14px",
      })?.clientWidth || 50;

    return width + 15;
  }, [maxYAxisVal]);

  return (
    <LineChartStyled
      height={props?.height}
      width={props?.width}
      className="dvsum-linechart"
    >
      {props?.onCollapse && (
        <LinkButton
          onClick={props.onCollapse}
          className="line-chart-collapse-btn"
        >
          {collapsedIconInCircle()}
        </LinkButton>
      )}
      <Chart
        horizontalGridLines={false}
        verticalGridLines={{
          style: { strokeWidth: "1px", stroke: "#e7e7e7" },
        }}
        // props
        {...props}
        //updating the exisiting values
        crosshair={
          typeof props?.crosshair === "object"
            ? {
                xFormatter: (val: number): string =>
                  format(new Date(val || 0), "P"),
                ...props?.crosshair,
              }
            : typeof props?.crosshair === "boolean"
            ? props?.crosshair
            : {
                xFormatter: (val: number): string =>
                  format(new Date(val || 0), "P"),
              }
        }
        yAxis={
          typeof props?.yAxis === "object"
            ? { ...yAxis, ...props?.yAxis }
            : typeof props?.yAxis === "boolean"
            ? props?.yAxis
            : yAxis
        }
        xAxis={
          typeof props?.xAxis === "object"
            ? { ...xAxis, ...props?.xAxis }
            : typeof props?.xAxis === "boolean"
            ? props?.xAxis
            : xAxis
        }
        xyPlot={{
          ...xyPlot,
          margin:
            typeof xyPlot?.margin === "object"
              ? {
                  left: maxWidthOfYAxisVal,
                  ...xyPlot?.margin,
                }
              : typeof xyPlot?.margin === "number"
              ? xyPlot?.margin
              : {
                  left: maxWidthOfYAxisVal,
                },
          //@ts-ignore
          yDomain: xyPlot?.yDomain || [0.1, maxYAxisVal?.maxValForDomain],
        }}
        data={updatedData}
      />
    </LineChartStyled>
  );
}

export default LineChart;
