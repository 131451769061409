export const DVSUM_COLOR_PALLETE = [
  "#2196F3",
  "#FF5722",
  "#7E57C2",
  "#4AD0D9",
  "#4CAF50",
  "#E91E63",
  "#FFA726",
  "#78909C",
  "#CE93D8",
  "#D4E157",
  "#9D8DFA",
  "#2F97C1",
];
