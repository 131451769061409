import { API_CONFIG } from "../../constants/apiconfig";

import { getRefParsedData, ReturnTypeOfRefParser } from "../../parsers";
import { RefTypes } from "../../parsers/refparser/refparser.types";

import { useGetAll } from "../baseservice";

import { CustomReactQueryHookReturnType } from "../services.types";

const { get_all_refs_type: allRefByType } = API_CONFIG;

export const useGetRefByType = (
  refType: RefTypes,
  parser?: any
): CustomReactQueryHookReturnType<ReturnTypeOfRefParser[]> => {
  return useGetAll({
    apiConfig: allRefByType,
    parser: parser || getRefParsedData,
    params: [refType],
  });
};
