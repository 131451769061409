import { useMemo } from "react";

//           <--BlankSlates Start-->
import { ruleDetailsBarChartSecBlankSlate } from "../../../../../../blankslates";
//           <--BankSlates End-->

//           <--Components Start-->
import DQScore from "../../../../../dqscore";
import { BarChart } from "../../../../../charts";

import Linkbutton from "../../../../../linkbutton";
import Statehandler from "../../../../../statehandler";
//           <--Components End-->

//           <--Styles Start-->
import { DataQualityRuleDetailsBarChartStyled } from "./dataqualityruledetailsbarchart.styles";
//           <--Styles End-->

//           <--SVGS Start-->
import { leftArrowIcon, rightArrowIcon } from "../../../../../../svgs";
//           <--SVGS End-->

//           <--Types Start-->
import { DataQualityRuleDetailsBarChartProps } from "./dataqualityruledetailsbarchart.types";
//           <--Types End-->

function DataQualityRuleDetailsBarChart({
  nodeId = "",
  ruleHistoryId = "",
  parsedData,
  isLoading,
  error,
  onPrevClick,
  onNextClick,
}: DataQualityRuleDetailsBarChartProps): JSX.Element {
  const {
    chart_data: chartData = [],
    date = "",
    dq_score: dqScore,
    next_id: nextId = "",
    prev_id: prevId = "",
  } = parsedData || {};

  const data = useMemo(() => {
    return chartData?.[0]?.data || [];
  }, [chartData]);

  const chartHeight = useMemo(() => data?.length * 55, [data]);

  return (
    <DataQualityRuleDetailsBarChartStyled
      data-testid={`data-quality-rule-details-bar-chart-component-${nodeId}-${ruleHistoryId}`}
    >
      <Statehandler
        isFetching={isLoading}
        error={error}
        blankSlate={ruleDetailsBarChartSecBlankSlate}
      >
        <div className="parent-wrapper">
          <div className="content-sec">
            <div className="header">
              <div className="time-travel-sec">
                <Linkbutton
                  className="history-chart-prev history-chart-btn"
                  data-testid={`data-quality-history-chart-prev-btn-${nodeId}`}
                  onClick={onPrevClick}
                  disabled={!prevId}
                  tooltipProps={{
                    title: !prevId ? "No data to show" : "",
                    placement: "left",
                  }}
                >
                  <span> {leftArrowIcon("25", "14")}</span>
                </Linkbutton>
                <div className="divider" />
                <div className="time-travel-sec-content">
                  <div
                    className="time-travel-date"
                    data-testid={`data-quality-history-chart-time-travel-date-${nodeId}`}
                  >
                    Time Travel: {date}
                  </div>
                  <div className="score-sec">
                    DQ Score:{" "}
                    <DQScore
                      {...dqScore}
                      data-testid={`data-quality-history-chart-dq-score-${nodeId}`}
                    />
                  </div>
                </div>
                <div className="divider" />
                <Linkbutton
                  className="history-chart-next history-chart-btn"
                  data-testid={`data-quality-history-chart-next-btn-${nodeId}`}
                  onClick={onNextClick}
                  disabled={!nextId}
                  tooltipProps={{
                    title: !nextId ? "No data to show" : "",
                    placement: "right",
                  }}
                >
                  <span> {rightArrowIcon("25", "14")}</span>
                </Linkbutton>
              </div>
            </div>
            <BarChart
              data-testid={`data-quality-history-chart-component-bar-chart-${nodeId}`}
              data={chartData}
              width="100%"
              height={chartHeight < 220 ? "250px" : `${chartHeight}px`}
              xyPlot={{
                margin: { right: 50 },
              }}
              yAxis={{
                style: {
                  fill: "#959596",
                  fontSize: "13px",
                  fontFamily: "OpenSansSemiBold",
                },
              }}
              xAxis={{
                tickTotal: 6,
                style: {
                  fill: "#959596",
                  fontSize: "14px",
                },
              }}
            />
          </div>
        </div>
      </Statehandler>
    </DataQualityRuleDetailsBarChartStyled>
  );
}
export default DataQualityRuleDetailsBarChart;
