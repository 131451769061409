import { Select } from "antd";
import styled, { createGlobalStyle } from "styled-components";

export const DvSumSelectFieldDropdownGlobalStyles = createGlobalStyle<{
  theme: any;
  hideDropdown?: boolean;
  dropDownMaxHeight?: string;
}>`
  .multiselect-dropdown-dvsum {
      display: ${(props): any => props?.hideDropdown && "none"};
      ${({ dropDownMaxHeight }): any =>
        dropDownMaxHeight && `max-height: ${dropDownMaxHeight};`}
      padding: 0px;
      .rc-virtual-list-holder {
        ${({ dropDownMaxHeight }): any =>
          dropDownMaxHeight && `max-height: ${dropDownMaxHeight}; !important`}
        .ant-select-item-option {
          padding: 5px 15px;
          color: ${({ theme }): string => theme.textColors.BLACK_13};
          font-size: 13px;
        }

        .ant-select-item-group:not(:first-of-type)::before {
          margin: unset;
        }

        .ant-select-item-option-active,
        .ant-select-item-option-selected {
          background: ${({ theme }): string => theme.bgColors.BLUE_23};
        }
        .ant-select-item-option-selected {
          .ant-select-item-option-state {
            display: none;
          }
        }
        .ant-select-item-option-disabled {
          opacity: 0.5;
        }
      }

      .ant-select-item-empty {
        display: ${(props): any => props.hideDropdown && "none"};
        min-height: unset;
        padding: 0px;

        .no-result-fount-content {
          background: ${(props): string => props.theme.bgColors.GREY_115};
          padding: 2px 6px;
          color: ${(props): string => props.theme.textColors.GREY_90};
        }
      }
    }
`;

export const SelectStyled = styled(Select)<{
  hideDropdown?: boolean;
  width?: string;
  height?: string;
  maxHeight?: string;
  multipleMode?: boolean;
  dropDownMaxHeight?: string;
}>`
  &.dvsum-select-field {
    font-size: 13px;
    width: ${(props): string => props.width || "467px"};

    :not(.ant-select-disabled):hover {
      .ant-select-selector {
        border-color: ${({ theme }): string =>
          theme.borderColors.LIGHT_GREY_40};
      }
    }

    .ant-select-selector {
      min-height: ${(props): string => props?.height || "34px"};
      max-height: ${({ maxHeight }): string => maxHeight || "62px"};
      overflow-y: auto;
      overflow-x: hidden;
      border: 1px solid
        ${(props): string => props.theme.borderColors.LIGHT_GREY_40};
      border-radius: 0px;
      :hover {
        border: 1px solid
          ${(props): string => props.theme.borderColors.LIGHT_GREY_40};
      }

      .ant-select-selection-item {
        color: ${(props): string => props.theme.textColors.BLACK_13};
        font-size: 13px;
        display: flex;
        align-items: center;

        .ant-select-selection-item-content {
          .custom-option {
            > svg {
              display: none;
            }
          }
        }
      }

      .ant-select-selection-placeholder {
        color: ${(props): string => props.theme.textColors.BLACK_60};
        font-size: 13px;
        display: flex;
        align-items: center;
      }
    }

    .ant-select-arrow {
      right: 12px;
    }

    .multiselect-dropdown-dvsum {
      display: ${(props): any => props?.hideDropdown && "none"};
      ${({ dropDownMaxHeight }): any =>
        dropDownMaxHeight && `max-height: ${dropDownMaxHeight};`}
      padding: 0px;
      .rc-virtual-list-holder {
        ${({ dropDownMaxHeight }): any =>
          dropDownMaxHeight && `max-height: ${dropDownMaxHeight}; !important`}
        .ant-select-item-option {
          padding: 5px 15px;
          color: ${({ theme }): string => theme.textColors.BLACK_13};
          font-size: 13px;
        }

        .ant-select-item-group:not(:first-of-type)::before {
          margin: unset;
        }

        .ant-select-item-option-active,
        .ant-select-item-option-selected {
          background: ${({ theme }): string => theme.bgColors.BLUE_23};
        }
        .ant-select-item-option-selected {
          .ant-select-item-option-state {
            display: none;
          }
        }
        .ant-select-item-option-disabled {
          opacity: 0.5;
        }
      }

      .ant-select-item-empty {
        display: ${(props): any => props.hideDropdown && "none"};
        min-height: unset;
        padding: 0px;

        .no-result-fount-content {
          background: ${(props): string => props.theme.bgColors.GREY_115};
          padding: 2px 6px;
          color: ${(props): string => props.theme.textColors.GREY_90};
        }
      }
    }
  }

  &.ant-select-multiple {
    .ant-select-selector {
      .ant-select-selection-item {
        height: 34px;
        border: solid 1px
          ${({ theme }): string => theme.borderColors.LIGHT_GREY_40};
        background-color: ${({ theme }): string => theme.bgColors.LIGHT_GREY_7};
        color: ${({ theme }): string => theme.textColors.BLACK_22};
        font-size: 13px;
      }
    }
  }

  &.dvsum-select-field.ant-select-disabled {
    .ant-select-selector {
      .ant-select-selection-item {
        color: ${({ theme }): string => theme.textColors.DARK_GREY_100};
      }
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: ${(props): string => props.theme.borderColors.YELLOW_10};
    box-shadow: none;
    outline: none;

    .ant-select-selection-placeholder {
      display: none;
    }

    .ant-select-selection-item {
      color: ${(props): string => props.theme.textColors.BLACK_13};
    }
  }
`;
